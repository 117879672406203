var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { WmButton, WmOption, WmSelect, WmSnackbar, } from "@watermarkinsights/ripple-react";
import Card from "../../features/common/component/Card";
import Inputfield from "../../features/common/component/InputField";
import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useLocation, useNavigate, useParams, useSearchParams, } from "react-router-dom";
import translate from "../../i18n/translate";
import { focusButtonComponent, isNullOrEmpty, } from "../../static/js/commons.js";
import { useDispatch, useSelector } from "react-redux";
import { getOrganizationAndChildOrganizations, applicationSelector, getAllApplicationTemplatesForOrg, saveApplication, resetFlags, resetApplicationSchedule, removeSchedule, initApplicationSchedule, updateApplication, updateApplicationStatus, updateSchedule, resetApplicationObj, getAllApplicationTemplatesForExtApp, closeSnackbar, copyUrlSnack, getTerms, } from "./ApplicationSlice";
import DiscardModal from "../../features/common/component/DiscardWmModal";
import PreviewApplication from "./PreviewApplication";
import ScheduleApplication from "./ScheduleApplication";
import ScheduleOverlapModal from "./ScheduleOverlapModal";
import { format } from "date-fns";
import DeleteModal from "../common/component/DeleteWmModal";
import { getApplication } from "./ApplicationSlice";
import { isEqual } from "lodash";
import SelectApplicationType from "./SelectApplicationType";
import { HOST_NAME } from "../../env-config";
import WmActionModal from "../../features/common/component/WmActionModal";
import { cohortSelector } from "../../features/cohorts/CohortSlice";
const DistributeApplication = (props) => {
    var _a, _b;
    const navigate = useNavigate();
    const location = useLocation();
    document.title =
        (((_a = location.state) === null || _a === void 0 ? void 0 : _a.previousPage) === "edit-menu" ? "Edit" : "Distribute") +
            " Application | Watermark";
    const { applicationUuid } = useParams();
    const [titleError, setTitleError] = useState(false);
    const [title, setTitle] = useState("");
    const [selectedOrg, setSelectedOrg] = useState("none");
    const [orgSelectionInvalid, setOrgSelectionInvalid] = useState(false);
    const [description, setDescription] = useState("");
    const [isFocused, setFocused] = useState(false);
    const [cantScheduleModal, setCantScheduleModal] = useState(false);
    const titleRef = useRef();
    const dispatch = useDispatch();
    const [isNewApp, setIsNewApp] = useState(false);
    const [showCopyUrlModal, setShowCopyUrlModal] = useState(false);
    const [termInvalid, setTermInvalid] = useState(false);
    const { organizationList, applicationTemplateListPerOrg, duplicateApplication, saveApplicationFulfilled, saveApplicationRejected, applicationSchedules, applicationSchedulesList, applicationObj, isFetchingData, snackNotifications, } = useSelector(applicationSelector);
    const { termList } = useSelector(cohortSelector);
    const currentDateTime = new Date();
    const scheduleUuidExists = applicationSchedulesList === null || applicationSchedulesList === void 0 ? void 0 : applicationSchedulesList.every((sche) => !isNullOrEmpty(sche.uuid));
    const activeScheduleExists = applicationSchedulesList === null || applicationSchedulesList === void 0 ? void 0 : applicationSchedulesList.some((sche) => new Date(sche.startDate) <= currentDateTime &&
        new Date(sche.endDate) >= currentDateTime);
    const upcomingScheduleExists = applicationSchedulesList === null || applicationSchedulesList === void 0 ? void 0 : applicationSchedulesList.some((sche) => scheduleUuidExists
        ? new Date(sche.startDate) > currentDateTime
        : new Date(sche.startDate) >= currentDateTime);
    const maxTemplatesAllowed = 3;
    const [numberOfTemplates, setNumberOfTemplates] = useState(1);
    const [selectedTemplates, setSelectedTemplates] = useState([
        {},
        {},
        {},
    ]);
    const [openDiscardModal, setOpenDiscardModal] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [scheduleCloseModal, setApplicationCloseModal] = useState(false);
    const [newSelectionOrgUuid, setNewSelectionOrgUuid] = useState("");
    const [previewApplication, setPreviewApplication] = useState(false);
    const [scheduleApplication, setScheduleApplication] = useState(false);
    const [currentScheduleObj, setCurrentScheduleObj] = useState({});
    const [isActiveSchedule, setIsActiveSchedule] = useState(false);
    const [overlapModalOpen, setOverlapModalOpen] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [deleteActiveSchedule, setDeleteActiveSchedule] = useState(false);
    const [scheduleId, setScheduleId] = useState(0);
    const [previewApplicationList, setPreviewApplicationList] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [templateUuid, setTemplateUuid] = useState(searchParams.get("template_uuid"));
    const [orgUuid, setOrgUuid] = useState(searchParams.get("org_uuid"));
    const [selectedType, setSelectedType] = useState(location.state.selectedApplicationType);
    const [openTypeSelectionModal, setOpenTypeSelectionModal] = useState(false);
    const [openTypeChangeInfoModal, setOpenTypeChangeInfoModal] = useState(false);
    const [openSubmissions, setOpenSubmissions] = useState(false);
    const templateTitleError = useIntl().formatMessage({
        id: "app.programApplications.distribute.application.title.error.message",
    });
    const duplicateTemplateTitleError = useIntl().formatMessage({
        id: "app.programApplications.distribute.application.duplicate.title.error.message",
    });
    const intApplicationDetailsTitle = useIntl().formatMessage({
        id: "app.programApplications.distribute.external.application.details.title",
    }, {
        type: "Internal",
    });
    const extApplicationDetailsTitle = useIntl().formatMessage({
        id: "app.programApplications.distribute.external.application.details.title",
    }, {
        type: "External",
    });
    const applicationDetailsInputPlaceholder = useIntl().formatMessage({
        id: "app.programApplications.distribute.application.details.input.placeholder",
    });
    const applicationDetailsDescriptionPlaceholder = useIntl().formatMessage({
        id: "app.programApplications.distribute.application.details.description.placeholder",
    });
    const buildApplicationTitle = useIntl().formatMessage({
        id: "app.programApplications.distribute.application.build.application.title",
    });
    const alignToOrganization = useIntl().formatMessage({
        id: "app.programApplications.distribute.application.build.application.org.title",
    });
    const orgHelpText = useIntl().formatMessage({
        id: "app.programApplications.distribute.application.build.application.org.help.text",
    });
    const orgErrorMessage = useIntl().formatMessage({
        id: "app.programApplications.distribute.application.build.application.org.error.message",
    });
    const orgDropdownLabel = useIntl().formatMessage({
        id: "app.programApplications.distribute.application.build.application.org.dropdown.label",
    });
    const searchPlaceholder = useIntl().formatMessage({
        id: "app.programApplications.distribute.application.build.application.org.dropdown.search.placeholder",
    });
    const selectTemplates = useIntl().formatMessage({
        id: "app.programApplications.distribute.application.build.application.templates.title",
    });
    const templateHelpText = useIntl().formatMessage({
        id: "app.programApplications.distribute.application.build.application.templates.help.text",
    });
    const externalAppTemplateHelpText = useIntl().formatMessage({
        id: "app.programApplications.distribute.application.build.external.application.templates.help.text",
    });
    const templateLabel = useIntl().formatMessage({
        id: "app.programApplications.distribute.application.build.application.templates.dropdown.label",
    });
    const tempalteSearchPlaceholder = useIntl().formatMessage({
        id: "app.programApplications.distribute.application.build.application.templates.dropdown.search.placeholder",
    });
    const templateErrorMessage = useIntl().formatMessage({
        id: "app.programApplications.distribute.application.build.application.templates.error.message",
    });
    const templateDropdownMessage = useIntl().formatMessage({
        id: "app.programApplications.distribute.application.build.application.templates.dropdown.message",
    });
    const addAnotherTemplate = useIntl().formatMessage({
        id: "app.programApplications.distribute.application.build.application.templates.add.another.template",
    });
    const dropdownDefaultOption = useIntl().formatMessage({
        id: "app.programApplications.distribute.application.build.application.templates.dropdown.default",
    });
    const discardModalContent = useIntl().formatMessage({
        id: "app.programApplications.distribute.application.discard.selection.content",
    });
    const discardModalHeading = useIntl().formatMessage({
        id: "app.programApplications.distribute.application.discard.selection.heading",
    });
    const discardModalAriaLabelValue = useIntl().formatMessage({
        id: "app.programApplications.distribute.application.discard.selection.aria.label",
    });
    const discardPrimaryText = useIntl().formatMessage({
        id: "app.programApplications.distribute.application.discard.selection.primary.text",
    });
    const discardSecondaryText = useIntl().formatMessage({
        id: "app.programApplications.distribute.application.discard.selection.secondary.text",
    });
    const discardContent = useIntl().formatMessage({
        id: "app.programApplications.distribute.application.discard.modal.content",
    });
    const discardHeading = useIntl().formatMessage({
        id: "app.programApplications.distribute.application.discard.modal.heading",
    });
    const discardModalPrimaryText = useIntl().formatMessage({
        id: "app.programApplications.distribute.application.discard.modal.primary.text",
    });
    const scheduleApplicationTitle = useIntl().formatMessage({
        id: "app.programApplications.distribute.application.schedule.application.title",
    });
    const scheduleAppOrgHelpText = useIntl().formatMessage({
        id: "app.programApplications.distribute.application.schedule.application.help.text",
    });
    const scheduleAppButtonText = useIntl().formatMessage({
        id: "app.programApplications.distribute.application.schedule.application.add.button",
    });
    const deleteModalAriaLabel = useIntl().formatMessage({
        id: "app.programApplications.distribute.application.schedule.delete.modal.aria.label",
    });
    const deleteContentActive = useIntl().formatMessage({
        id: "app.programApplications.distribute.application.schedule.delete.modal.content.active",
    });
    const deleteModalHeading = useIntl().formatMessage({
        id: "app.programApplications.distribute.application.schedule.delete.modal.title",
    });
    const deleteContentFuture = useIntl().formatMessage({
        id: "app.programApplications.distribute.application.schedule.delete.modal.content.future",
    });
    const closeAppModalHeader = useIntl().formatMessage({
        id: "app.programApplications.application.close.modal.header",
    });
    const closeAppModalContent = useIntl().formatMessage({
        id: "app.programApplications.application.status.open.modal.content",
    });
    const closeAppModalPrimaryText = useIntl().formatMessage({
        id: "app.programApplications.application.close.modal.primary.text",
    });
    const closeAppModalSecondaryText = useIntl().formatMessage({
        id: "app.programApplications.application.close.modal.secondary.text",
    });
    const typeChangeInfoModalContent = useIntl().formatMessage({
        id: "app.programApplications.distribute.application.type.change.modal.content",
    });
    const typeChangeInfoModalHeading = useIntl().formatMessage({
        id: "app.programApplications.distribute.application.type.change.modal.heading",
    });
    const applicationSettingsTitle = useIntl().formatMessage({
        id: "app.programApplications.distribute.application.settings.title",
    });
    const typeChangeInfoModalPrimary = useIntl().formatMessage({
        id: "app.programApplications.distribute.application.type.change.modal.primary.text",
    });
    const typeChangeInfoModalSecondary = useIntl().formatMessage({
        id: "global.cancel",
    });
    const appUrlLabel = useIntl().formatMessage({
        id: "app.programApplications.distribute.application.external.application.url",
    });
    const appUrlNotAvailablePlaceholder = useIntl().formatMessage({
        id: "app.programApplications.distribute.application.external.url.not.available",
    });
    const copyUrlModalHeading = useIntl().formatMessage({
        id: "app.programApplications.distribute.external.application.url.created",
    });
    const copyUrlModalPrimaryText = useIntl().formatMessage({
        id: "app.programApplications.distribute.external.application.save.and.copy.url",
    }, { value: "Now" });
    const copyUrlModalSecondaryText = useIntl().formatMessage({
        id: "app.programApplications.distribute.external.application.save.and.copy.url",
    }, { value: "Later" });
    const termLabel = useIntl().formatMessage({
        id: "app.programApplications.template.standard.template.field.admission.term",
    });
    const dropdownRequiredErrorMessage = useIntl().formatMessage({
        id: "app.programApplications.student.application.dropdown.required.error.message",
    });
    const [initDataFetched, setInitDataFetched] = useState(false);
    const [initDataFilled, setInitDataFilled] = useState(false);
    const [admissionTerm, setAdmissionTerm] = useState("");
    // on load
    useEffect(() => {
        var _a;
        selectedType === "INTERNAL" &&
            dispatch(getOrganizationAndChildOrganizations()).then(() => {
                var _a;
                (_a = document.getElementById("input-application-title")) === null || _a === void 0 ? void 0 : _a.focus();
            });
        selectedType === "EXTERNAL" &&
            dispatch(getAllApplicationTemplatesForExtApp()).then(() => {
                var _a;
                (_a = document.getElementById("input-application-title")) === null || _a === void 0 ? void 0 : _a.focus();
            });
        (_a = document.getElementById("input-application-title")) === null || _a === void 0 ? void 0 : _a.focus();
    }, [selectedType]);
    useEffect(() => {
        let orgUuid = localStorage.getItem("orgUuid");
        if (applicationUuid) {
            Promise.all([
                dispatch(getApplication({ applicationUuid })),
                dispatch(getTerms({
                    orgUuid: orgUuid,
                })),
            ]).then(() => setInitDataFetched(true));
        }
        else {
            dispatch(getTerms({
                orgUuid: orgUuid,
            }));
            dispatch(resetApplicationObj());
        }
    }, [applicationUuid]);
    useEffect(() => {
        if (initDataFetched) {
            setTitle(applicationObj.title);
            setDescription(applicationObj.description);
            setOrgUuid(applicationObj.organizationUuid);
            setSelectedOrg(applicationObj.organizationUuid);
            !isNullOrEmpty(applicationObj.organizationUuid) &&
                updateSelectedOrgHandler({ preventDefault: () => { } }, applicationObj.organizationUuid);
            setNumberOfTemplates(applicationObj.applicationAppTemplates.length);
            setSelectedTemplates(applicationObj.applicationAppTemplates.map((obj, index) => {
                return {
                    templateUuid: obj.applicationTemplate.uuid,
                    invalid: false,
                    sortOrder: index,
                };
            }));
            dispatch(initApplicationSchedule({
                applicationSchedules: applicationObj.applicationSchedules,
            }));
            setSelectedType(applicationObj.type);
            setOpenSubmissions("OPEN" === applicationObj.status);
            setAdmissionTerm(applicationObj.admissionTerm);
            setInitDataFilled(true);
        }
    }, [initDataFetched]);
    const closeDistributeApplication = () => {
        var _a, _b;
        let oldTemplatesList = Array();
        let templatesList = Array();
        (_a = applicationObj === null || applicationObj === void 0 ? void 0 : applicationObj.applicationAppTemplates) === null || _a === void 0 ? void 0 : _a.forEach((applicationTemplate, i) => {
            oldTemplatesList.push({
                templateUuid: applicationTemplate.applicationTemplate.uuid,
                sortOrder: i,
            });
        });
        selectedTemplates.forEach((applicationTemplate) => {
            templatesList.push({
                templateUuid: applicationTemplate.templateUuid,
                sortOrder: applicationTemplate.sortOrder,
            });
        });
        if (!(applicationObj === null || applicationObj === void 0 ? void 0 : applicationObj.uuid) &&
            (!isNullOrEmpty(title) ||
                !isNullOrEmpty(description) ||
                !isSelectedOrgInvalid(selectedOrg) ||
                (selectedType === "EXTERNAL" &&
                    !isEqual("OPEN" === (applicationObj === null || applicationObj === void 0 ? void 0 : applicationObj.status), openSubmissions)) ||
                (selectedType === "EXTERNAL" &&
                    templatesList.filter((temp) => temp.templateUuid && temp.templateUuid !== "none").length))) {
            setOpenModal(true);
        }
        else if ((applicationObj === null || applicationObj === void 0 ? void 0 : applicationObj.uuid) &&
            (!isEqual(applicationObj.title, title) ||
                !isEqual(applicationObj.description, description) ||
                !isEqual(applicationObj.organizationUuid, selectedOrg) ||
                !isEqual(applicationObj.applicationSchedules, applicationSchedulesList) ||
                !isEqual(oldTemplatesList, templatesList) ||
                (selectedType === "EXTERNAL" &&
                    !isEqual("OPEN" === (applicationObj === null || applicationObj === void 0 ? void 0 : applicationObj.status), openSubmissions)))) {
            setOpenModal(true);
        }
        else {
            dispatch(resetApplicationSchedule());
            navigate({ pathname: "/programApplications" }, {
                state: {
                    appFilterValues: Object.assign({}, (_b = location.state) === null || _b === void 0 ? void 0 : _b.appFilterValues),
                    currentTab: "item-1",
                    focusElementId: "app-menu-" + (applicationObj === null || applicationObj === void 0 ? void 0 : applicationObj.uuid),
                },
            });
            setIsActiveSchedule(false);
        }
    };
    const titleHandler = (event) => {
        event.preventDefault();
        setTitle(event.target.value);
        if (titleError) {
            setTitleError(false);
            dispatch(resetFlags());
        }
    };
    const desciptionHandler = (event) => {
        event.preventDefault();
        setDescription(event.target.value);
    };
    const focusHandler = (isfocus) => {
        setFocused(isfocus);
    };
    const saveHandler = (event) => {
        var _a, _b, _c;
        event.preventDefault();
        let titleError = false;
        let orgError = false;
        let errorInTemplates = false;
        let termError = false;
        if (title === "" || title.trim() === "") {
            setTitleError(true);
            titleError = true;
            (_a = document.getElementById("input-application-title")) === null || _a === void 0 ? void 0 : _a.focus();
        }
        if (isSelectedOrgInvalid(selectedOrg) && selectedType !== "EXTERNAL") {
            setOrgSelectionInvalid(true);
            orgError = true;
            if (!isNullOrEmpty(title)) {
                (_b = document.getElementById("organization-select")) === null || _b === void 0 ? void 0 : _b.focus();
            }
        }
        if (selectedType === "EXTERNAL" &&
            (admissionTerm === "" || admissionTerm === undefined)) {
            setTermInvalid(true);
            termError = true;
            (title === null || title === void 0 ? void 0 : title.trim()) !== "" &&
                ((_c = document.getElementById("term-dropdown-admission-term")) === null || _c === void 0 ? void 0 : _c.focus());
        }
        if (selectedTemplates) {
            let finalTemplatesObject = [...selectedTemplates].map((x, i) => {
                if (i < numberOfTemplates &&
                    (x.templateUuid === "none" ||
                        x.templateUuid === "" ||
                        x.templateUuid === undefined)) {
                    x.invalid = true;
                    errorInTemplates = true;
                }
                return x;
            });
            if (!isNullOrEmpty(title) &&
                !isNullOrEmpty(admissionTerm) &&
                (!isSelectedOrgInvalid(selectedOrg) || selectedType === "EXTERNAL")) {
                let tempIndex = finalTemplatesObject.findIndex((item) => item.invalid === true);
                setTimeout(() => {
                    var _a;
                    (_a = document.getElementById("template-select-" + tempIndex)) === null || _a === void 0 ? void 0 : _a.focus();
                }, 10);
            }
            setSelectedTemplates(finalTemplatesObject);
            if (titleError || orgError || errorInTemplates || termError) {
                return;
            }
            let templatesList = Array();
            let count = 0;
            selectedTemplates.forEach((applicationTemplate) => {
                if (!applicationTemplate.invalid &&
                    applicationTemplate.templateUuid != undefined) {
                    templatesList.push({
                        templateUuid: applicationTemplate.templateUuid,
                        sortOrder: applicationTemplate.sortOrder,
                    });
                    count += 1;
                }
            });
            if (templatesList.length > 0 && templatesList.length === count) {
                let applicationObjToSend = {
                    uuid: "",
                    title: title.trim(),
                    description: description,
                    organizationUuid: selectedOrg == "none" ? "" : selectedOrg,
                    status: openSubmissions && selectedType === "EXTERNAL" ? "OPEN" : "CLOSED",
                    applicationTemplates: templatesList,
                    applicationSchedulesList: applicationSchedulesList,
                    type: selectedType,
                    admissionTerm: admissionTerm,
                };
                if (applicationObj === null || applicationObj === void 0 ? void 0 : applicationObj.uuid) {
                    applicationObjToSend = Object.assign(Object.assign({}, applicationObjToSend), { uuid: applicationObj.uuid });
                    dispatch(updateApplication(applicationObjToSend)).then((res) => {
                        if (res.type.indexOf("fulfilled") > 0) {
                            dispatch(resetApplicationSchedule());
                            let oldTemplates = [
                                ...applicationObj.applicationAppTemplates,
                            ].map((obj, i) => {
                                return {
                                    templateUuid: obj.applicationTemplate.uuid,
                                    sortOrder: i,
                                };
                            });
                        }
                    });
                }
                else {
                    setIsNewApp(true);
                    dispatch(saveApplication(applicationObjToSend)).then((res) => {
                        if (res.type.indexOf("fulfilled") > 0)
                            dispatch(resetApplicationSchedule());
                    });
                }
            }
        }
    };
    const updateSelectedOrgHandler = (event, newOrgUuid) => {
        event.preventDefault();
        if (((selectedOrg === "" || selectedOrg === "none") &&
            !isSelectedOrgInvalid(newOrgUuid)) ||
            isSelectedTemplatesEmpty(selectedTemplates)) {
            setSelectedOrg(newOrgUuid);
            if (!(selectedOrg === "" || selectedOrg === "none"))
                resetTemplateDropdownToInitialState();
            if (!isSelectedOrgInvalid(newOrgUuid))
                dispatch(getAllApplicationTemplatesForOrg({ orgUuid: newOrgUuid }));
        }
        else if (newOrgUuid !== selectedOrg) {
            setOpenDiscardModal(true);
            setNewSelectionOrgUuid(newOrgUuid);
        }
        setOrgSelectionInvalid(false);
    };
    const isSelectedOrgInvalid = (selectedOrg) => {
        return selectedOrg === "" || selectedOrg === "none";
    };
    const isSelectedTemplatesEmpty = (selectedTemplates) => {
        for (let x of selectedTemplates) {
            if (!(x.templateUuid === undefined ||
                x.templateUuid === "" ||
                x.templateUuid === "none")) {
                return false;
            }
        }
        return true;
    };
    const updateSelectedTemplatesHandler = (event, templateUuid, index) => {
        event.preventDefault();
        setSelectedTemplates((prev) => {
            let arr = [...prev];
            let count = 0;
            let templateChangeSameOrder = -1;
            arr.forEach((appTemplate, seq) => {
                if (!appTemplate.invalid && !isNullOrEmpty(appTemplate.templateUuid)) {
                    if (seq !== index) {
                        count += 1;
                    }
                    else {
                        templateChangeSameOrder = appTemplate.sortOrder;
                    }
                }
            });
            arr[index] = {
                templateUuid,
                invalid: false,
                sortOrder: templateChangeSameOrder !== -1 ? templateChangeSameOrder : count,
            };
            return arr;
        });
    };
    const addTemplateHandler = (event) => {
        event.preventDefault();
        setNumberOfTemplates((prev) => prev + 1);
        setSelectedTemplates((prev) => {
            let arr = [...prev];
            arr[numberOfTemplates] = {
                templateUuid: "",
                invalid: false,
                sortOrder: 0,
            };
            return arr;
        });
        setTimeout(() => {
            var _a;
            if (!isSelectedOrgInvalid(selectedOrg)) {
                (_a = document
                    .getElementById("template-select-" + numberOfTemplates)) === null || _a === void 0 ? void 0 : _a.focus();
            }
        }, 10);
    };
    const deleteTemplateSelectionHandler = (event, index) => {
        event.preventDefault();
        setSelectedTemplates((prev) => {
            prev.splice(index, 1);
            let arr = [...prev];
            arr = arr.map((appTemplate, seq) => {
                appTemplate.sortOrder = seq;
                return appTemplate;
            });
            return arr;
        });
        setNumberOfTemplates((prev) => prev - 1);
        if (numberOfTemplates === 2) {
            focusButtonComponent("add-template-btn");
        }
        else if (numberOfTemplates > 2) {
            focusButtonComponent("remove-template-btn-" + (index - 1));
        }
    };
    const discardChanges = (event) => __awaiter(void 0, void 0, void 0, function* () {
        event.preventDefault();
        setSelectedOrg(newSelectionOrgUuid);
        resetTemplateDropdownToInitialState();
        setOpenDiscardModal(false);
        setTimeout(() => {
            var _a;
            (_a = document.getElementById("organization-select")) === null || _a === void 0 ? void 0 : _a.focus();
        }, 100);
        if (!isSelectedOrgInvalid(newSelectionOrgUuid))
            dispatch(getAllApplicationTemplatesForOrg({ orgUuid: newSelectionOrgUuid }));
    });
    const discardAllChanges = (event) => __awaiter(void 0, void 0, void 0, function* () {
        var _c;
        event.preventDefault();
        setTitle("");
        setDescription("");
        setSelectedOrg("");
        resetTemplateDropdownToInitialState();
        dispatch(resetFlags());
        setOpenModal(false);
        dispatch(resetApplicationSchedule());
        navigate({ pathname: "/programApplications" }, {
            state: {
                appFilterValues: Object.assign({}, (_c = location.state) === null || _c === void 0 ? void 0 : _c.appFilterValues),
                currentTab: "item-1",
                focusElementId: (applicationObj === null || applicationObj === void 0 ? void 0 : applicationObj.uuid)
                    ? "app-menu-" + (applicationObj === null || applicationObj === void 0 ? void 0 : applicationObj.uuid)
                    : "distribute-application",
            },
        });
    });
    const resetTemplateDropdownToInitialState = () => {
        setNumberOfTemplates(1);
        setSelectedTemplates([
            {},
            {},
            {},
        ]);
    };
    const closeDiscardModalHandler = (event) => {
        event.preventDefault();
        setOpenDiscardModal(false);
        setTimeout(() => {
            var _a;
            (_a = document.getElementById("organization-select")) === null || _a === void 0 ? void 0 : _a.focus();
        }, 100);
    };
    const closeApplicationModal = (event) => {
        event.preventDefault();
        setApplicationCloseModal(false);
        focusButtonComponent("schedule-close-now-" + scheduleId);
    };
    const updateApplicationHandler = (even) => {
        let uuid = applicationObj.uuid;
        let scheduleUuid = currentScheduleObj === null || currentScheduleObj === void 0 ? void 0 : currentScheduleObj.uuid;
        let status = "close";
        dispatch(removeSchedule({ scheduleId }));
        dispatch(updateSchedule({ applicationUuid, scheduleUuid }));
        dispatch(updateApplicationStatus({ uuid, status }));
        setApplicationCloseModal(false);
    };
    const closeModalHandler = (event) => {
        var _a;
        event.preventDefault();
        setOpenModal(false);
        (_a = document.getElementById("input-application-title")) === null || _a === void 0 ? void 0 : _a.focus();
    };
    const closeApplicationHandler = (event, schedule, id) => {
        event.preventDefault();
        setApplicationCloseModal(true);
        setCurrentScheduleObj(schedule);
        setScheduleId(id);
    };
    const updateScheduleHandler = (event, schedule) => {
        event.preventDefault();
        setScheduleApplication(true);
        setCurrentScheduleObj(schedule);
        if (new Date(schedule.startDate) <= currentDateTime &&
            (isNullOrEmpty(schedule.endDate)) || new Date(schedule.endDate) >= currentDateTime) {
            setIsActiveSchedule(true);
        }
    };
    const applicationPreviewHandler = (event) => {
        event.preventDefault();
        setPreviewApplication(true);
        let previewAppList = [];
        selectedTemplates.forEach((appTemp) => {
            var _a;
            if (appTemp.templateUuid && appTemp.templateUuid !== "none") {
                previewAppList.push((_a = applicationTemplateListPerOrg.find((templateResponse) => templateResponse.template.uuid === appTemp.templateUuid)) === null || _a === void 0 ? void 0 : _a.template);
            }
        });
        setPreviewApplicationList(previewAppList);
    };
    const isTemplateSelected = (selectedTemplates) => {
        for (let x of selectedTemplates) {
            if (x.templateUuid !== undefined && x.templateUuid !== "") {
                return true;
            }
        }
        return false;
    };
    const deleteScheduleHandler = (event) => {
        event.preventDefault();
        dispatch(removeSchedule({ scheduleId }));
        setOpenDeleteModal(false);
        setDeleteActiveSchedule(false);
        if (scheduleId === 0) {
            setTimeout(() => {
                focusButtonComponent("delete-schedule-0");
            }, 10);
        }
        else {
            focusButtonComponent("delete-schedule-" + (scheduleId - 1).toString());
        }
        if ((applicationSchedulesList === null || applicationSchedulesList === void 0 ? void 0 : applicationSchedulesList.length) === 1) {
            focusButtonComponent("schedule-app-btn");
        }
    };
    useEffect(() => {
        var _a, _b;
        if (saveApplicationFulfilled) {
            if (isNewApp && selectedType === "EXTERNAL") {
                setShowCopyUrlModal(true);
            }
            else {
                dispatch(resetFlags());
                navigate({ pathname: "/programApplications" }, {
                    state: {
                        appFilterValues: Object.assign({}, (_a = location.state) === null || _a === void 0 ? void 0 : _a.appFilterValues),
                        currentTab: "item-1",
                        focusElementId: "app-menu-" + (applicationObj === null || applicationObj === void 0 ? void 0 : applicationObj.uuid),
                    },
                });
            }
            return;
        }
        else if (saveApplicationRejected) {
            if (duplicateApplication) {
                setTitleError(true);
                (_b = document.getElementById("input-application-title")) === null || _b === void 0 ? void 0 : _b.focus();
            }
        }
    }, [
        duplicateApplication,
        saveApplicationFulfilled,
        saveApplicationRejected,
        navigate,
    ]);
    const renderScheduleCard = (schedule, isModalView, i, category) => {
        return (_jsxs("div", Object.assign({ className: "schedule-card flex-row -justified -verticallycentered" }, { children: [_jsxs("div", Object.assign({ className: "schedule-details" }, { children: [_jsx("h5", Object.assign({ className: "schedule-title" }, { children: schedule.name })), _jsxs("p", Object.assign({ className: "schedule-date" }, { children: [_jsxs("span", Object.assign({ className: "schedule-date-label" }, { children: [translate("app.programApplications.distribute.application.schedule.overlap.open.date.label"), " "] })), _jsxs("span", Object.assign({ className: "schedule-date-value" }, { children: [format(new Date(schedule.startDate), "MMM d, yyyy"), translate("app.programApplications.at"), format(new Date(schedule.startDate), "h:mm a")] }))] })), _jsxs("p", Object.assign({ className: "schedule-date -close" }, { children: [_jsxs("span", Object.assign({ className: "schedule-date-label" }, { children: [translate("app.programApplications.distribute.application.schedule.overlap.close.date.label"), " "] })), _jsxs("span", Object.assign({ className: "schedule-date-value" }, { children: [schedule.endDate
                                            ? format(new Date(schedule.endDate), "MMM d, yyyy")
                                            : "N/A", schedule.endDate && translate("app.programApplications.at"), schedule.endDate && format(new Date(schedule.endDate), "h:mm a"), category === "active" && schedule.uuid && (_jsx(WmButton, Object.assign({ className: "close-now-btn", id: "schedule-close-now-" + i, "button-type": "textonly", icon: "f425", onClick: (event) => closeApplicationHandler(event, schedule, i) }, { children: translate("app.programApplications.application.close.schedule") })))] }))] }))] })), !isModalView && (_jsxs("div", Object.assign({ className: "btns flex-row -justified -verticallycentered" }, { children: [category === "active" ? (_jsx(WmButton, { id: "delete-schedule-" + i, buttonType: "icononly", tooltip: "Delete", icon: "f1c0", onClick: () => {
                                setDeleteActiveSchedule(true);
                                setScheduleId(i);
                            } })) : (_jsx(WmButton, { id: "delete-schedule-" + i, buttonType: "icononly", tooltip: "Delete", icon: "f1c0", onClick: () => {
                                setOpenDeleteModal(true);
                                setScheduleId(i);
                            } })), _jsx(WmButton, { id: "edit-schedule-" + i, buttonType: "icononly", tooltip: "Edit", icon: "f3eb", onClick: (event) => {
                                updateScheduleHandler(event, schedule);
                                setScheduleId(i);
                            } })] })))] }), schedule.name + i));
    };
    useEffect(() => {
        if (orgUuid) {
            setSelectedOrg(orgUuid);
            updateSelectedOrgHandler({ preventDefault: () => { } }, orgUuid);
        }
    }, [organizationList]);
    useEffect(() => {
        if (templateUuid) {
            updateSelectedTemplatesHandler({ preventDefault: () => { } }, templateUuid, 0);
            setOrgUuid(null);
            setTemplateUuid(null);
        }
    }, [applicationTemplateListPerOrg]);
    const finishedSnackHandler = (event) => {
        event.preventDefault();
        dispatch(closeSnackbar(event.detail.id));
    };
    const URL = (applicationObj === null || applicationObj === void 0 ? void 0 : applicationObj.uuid)
        ? `${HOST_NAME}/externalApplications/${applicationObj === null || applicationObj === void 0 ? void 0 : applicationObj.uuid}`
        : "";
    const applicationDetailsCard = (type) => (_jsxs(Card, Object.assign({ classList: "app-details-card", headerTitle: type === "INTERNAL"
            ? intApplicationDetailsTitle
            : extApplicationDetailsTitle, headerLink: (applicationObj === null || applicationObj === void 0 ? void 0 : applicationObj.submissionExist) ? ("") : (_jsx(WmButton, Object.assign({ id: "edit-app-type-btn", "button-type": "textonly", icon: "f3eb", onClick: () => setOpenTypeChangeInfoModal(true) }, { children: translate("app.programApplications.distribute.application.change.application.type") }))) }, { children: [_jsx(Inputfield, { titleError: titleError, dataLabel: "Title", modalTitle: title, updateTitleHandler: titleHandler, inputRef: titleRef, modalFor: "application", modalId: "application-title", required: true, customError: true, customErrorMessage: duplicateApplication
                    ? duplicateTemplateTitleError
                    : templateTitleError, placeholder: applicationDetailsInputPlaceholder }), _jsxs("div", Object.assign({ className: "description-field" }, { children: [_jsx("label", Object.assign({ className: "textfieldlabel ", htmlFor: "application-description" }, { children: translate("global.description") })), _jsx("div", Object.assign({ className: isFocused ? "textfield is-focused" : "textfield" }, { children: _jsxs("div", Object.assign({ className: "charcount -textarea" }, { children: [_jsx("textarea", { className: "textfieldinput autosize-textarea", id: "application-description", "aria-required": "true", value: description, maxLength: 500, "aria-describedby": "field-description", onChange: desciptionHandler, onFocus: () => focusHandler(true), onBlur: () => focusHandler(false), "aria-label": "Description", placeholder: applicationDetailsDescriptionPlaceholder }), _jsx("div", Object.assign({ className: "count", "aria-live": "polite", "aria-atomic": true, "aria-label": description.length < 500
                                        ? "Character Entered"
                                        : "Character Limit has been reached" }, { children: String(description.length) + "/500" })), _jsx("span", Object.assign({ id: "field-description", className: "max-count-label" }, { children: translate("app.programApplications.distribute.application.description.message") }))] })) }))] })), type === "EXTERNAL" && (_jsxs(_Fragment, { children: [_jsxs(WmSelect, Object.assign({ label: termLabel, labelPosition: "top", id: "term-dropdown-admission-term", disabled: false, requiredField: true, errorMessage: termInvalid ? dropdownRequiredErrorMessage : "" }, { children: [_jsx(WmOption, Object.assign({ id: "option-default-comp-admission-term-SelectOption", selected: isNullOrEmpty(admissionTerm), value: "" }, { children: translate("app.programApplications.select.option") }), "empty"), termList === null || termList === void 0 ? void 0 : termList.map((option) => (_jsx(WmOption, Object.assign({ id: `option-${option.uuid}`, selected: option.uuid === admissionTerm, value: option.uuid, onWmOptionSelected: (event) => {
                                    event.preventDefault();
                                    setAdmissionTerm(event.target.value);
                                    if (event.target.value !== "") {
                                        setTermInvalid(false);
                                    }
                                    else {
                                        setTermInvalid(true);
                                    }
                                } }, { children: option.name }), "option" + option.name)))] }), "select-admission-term-" + admissionTerm), _jsxs("div", Object.assign({ className: `application-url-field ${(applicationObj === null || applicationObj === void 0 ? void 0 : applicationObj.uuid) ? "url-populated" : "url-blank"}` }, { children: [_jsx(Inputfield, { titleError: false, dataLabel: appUrlLabel, modalTitle: (applicationObj === null || applicationObj === void 0 ? void 0 : applicationObj.uuid) ? URL : "", updateTitleHandler: () => { }, disabled: true, inputRef: titleRef, modalFor: "application-url", modalId: "application-url", required: false }), (applicationObj === null || applicationObj === void 0 ? void 0 : applicationObj.uuid) ? (_jsx(WmButton, { "button-type": "navigational", tooltip: "Copy URL", icon: "f18f", tooltipPosition: "bottom", className: "copy-url", onClick: () => {
                                    navigator.clipboard.writeText(URL);
                                    dispatch(copyUrlSnack());
                                } })) : (_jsx("p", Object.assign({ className: "app-url-info" }, { children: appUrlNotAvailablePlaceholder })))] }))] }))] })));
    const steps = {
        INTERNAL: ["org", "templates"],
        EXTERNAL: ["templates"],
    };
    const buildApplicationCard = (type) => (_jsxs(Card, Object.assign({ classList: "build-app-card", headerTitle: buildApplicationTitle }, { children: [type != null &&
                steps[type].map((step, i) => {
                    if (step === "org") {
                        return (_jsxs("div", Object.assign({ className: "build-app-section" }, { children: [_jsx("h3", Object.assign({ className: "build-app-labels" }, { children: (steps[type].length > 1 ? `${i + 1}. ` : "") +
                                        alignToOrganization })), _jsx("p", Object.assign({ className: "build-app-infoText" }, { children: orgHelpText })), _jsxs(WmSelect, Object.assign({ id: "organization-select", label: orgDropdownLabel, search: true, searchPlaceholder: searchPlaceholder, requiredField: true, errorMessage: orgSelectionInvalid ? orgErrorMessage : "", disabled: applicationObj === null || applicationObj === void 0 ? void 0 : applicationObj.submissionExist }, { children: [_jsx(WmOption, Object.assign({ value: "none", onWmOptionSelected: (event) => {
                                                updateSelectedOrgHandler(event, "none");
                                            }, selected: "none" ===
                                                (isNullOrEmpty(selectedOrg) ? "none" : selectedOrg) }, { children: dropdownDefaultOption }), "default"), organizationList.map((org) => {
                                            return (_jsx(WmOption, Object.assign({ "data-testid": `option-${org.uuid}`, value: org.uuid, onWmOptionSelected: (event) => {
                                                    updateSelectedOrgHandler(event, org.uuid);
                                                }, selected: org.uuid === selectedOrg }, { children: org.title }), org.uuid));
                                        })] }), `select-org-${selectedOrg}-${openDiscardModal}`), (applicationObj === null || applicationObj === void 0 ? void 0 : applicationObj.submissionExist) && (_jsxs("p", Object.assign({ className: "disabled-text-msg org-select" }, { children: [_jsx("span", { className: "mdi mdi-lock" }), translate("app.programApplications.distribute.application.build.application.disabled.mode.message", {
                                            value: "organization",
                                        })] })))] }), step + i));
                    }
                    else if (step == "templates") {
                        return (_jsxs("div", Object.assign({ className: `build-app-section template-section ${type === "EXTERNAL" ? "external-templates" : ""}` }, { children: [_jsx("h3", Object.assign({ className: "build-app-labels" }, { children: (steps[type].length > 1 ? `${i + 1}. ` : "") +
                                        selectTemplates })), _jsx("p", Object.assign({ className: "build-app-infoText" }, { children: type === "INTERNAL"
                                        ? templateHelpText
                                        : externalAppTemplateHelpText })), isSelectedOrgInvalid(selectedOrg) && type !== "EXTERNAL" ? (_jsx("div", Object.assign({ className: "align-org-msg" }, { children: templateDropdownMessage }))) : (_jsxs(_Fragment, { children: [[...Array(numberOfTemplates)].map((elem, i) => {
                                            return (_jsxs("div", Object.assign({ className: "flex-row -justified -verticallycentered" }, { children: [_jsxs(WmSelect, Object.assign({ id: `template-select-${i}`, label: `${templateLabel} ${i + 1}`, className: "template-select", search: true, searchPlaceholder: tempalteSearchPlaceholder, requiredField: true, errorMessage: selectedTemplates[i].invalid
                                                            ? templateErrorMessage
                                                            : "", disabled: applicationObj === null || applicationObj === void 0 ? void 0 : applicationObj.submissionExist }, { children: [_jsx(WmOption, Object.assign({ value: "none", selected: !selectedTemplates[i].templateUuid ||
                                                                    selectedTemplates[i].templateUuid === "none" ||
                                                                    !applicationTemplateListPerOrg.length, onWmOptionSelected: (event) => {
                                                                    updateSelectedTemplatesHandler(event, "none", i);
                                                                } }, { children: dropdownDefaultOption }), "default" + i), applicationTemplateListPerOrg.map((templateResponse) => {
                                                                let template = templateResponse.template;
                                                                let disabled = (selectedTemplates.filter((temp) => template.uuid === temp.templateUuid).length > 0 &&
                                                                    selectedTemplates
                                                                        .map((elem) => elem.templateUuid)
                                                                        .indexOf(template.uuid) !== i) ||
                                                                    template.status === "ARCHIVED";
                                                                if (template.uuid !==
                                                                    selectedTemplates[i].templateUuid &&
                                                                    template.status === "ARCHIVED") {
                                                                    return "";
                                                                }
                                                                return (_jsx(WmOption, Object.assign({ value: template.uuid, selected: template.uuid ===
                                                                        selectedTemplates[i].templateUuid, disabled: disabled, onWmOptionSelected: (event) => {
                                                                        !disabled &&
                                                                            updateSelectedTemplatesHandler(event, template.uuid, i);
                                                                    }, "data-testid": `option-${template.uuid}` }, { children: template.title +
                                                                        (template.status === "ARCHIVED"
                                                                            ? " [Archived]"
                                                                            : "") }), template.uuid +
                                                                    "-" +
                                                                    i +
                                                                    "-" +
                                                                    selectedTemplates.toString()));
                                                            })] }), `template-select-${i}-${selectedTemplates.toString()}-${Math.random()}`), numberOfTemplates !== 1 &&
                                                        !(applicationObj === null || applicationObj === void 0 ? void 0 : applicationObj.submissionExist) && (_jsx(WmButton, { id: `remove-template-btn-${i}`, className: selectedTemplates[i].invalid
                                                            ? "remove-template-btn"
                                                            : "", "button-type": "icononly", tooltip: "Remove Template", tooltipPosition: "top", icon: "f1c0", onClick: (event) => {
                                                            deleteTemplateSelectionHandler(event, i);
                                                        } }))] }), `template-item-${i}-${applicationTemplateListPerOrg.toString()}`));
                                        }), (applicationObj === null || applicationObj === void 0 ? void 0 : applicationObj.submissionExist) && (_jsxs("p", Object.assign({ className: "disabled-text-msg" }, { children: [_jsx("span", { className: "mdi mdi-lock" }), translate("app.programApplications.distribute.application.build.application.disabled.mode.message", {
                                                    value: "templates",
                                                })] })))] })), _jsx("div", Object.assign({ className: numberOfTemplates < maxTemplatesAllowed
                                        ? ""
                                        : "hide-add-btn" }, { children: !(applicationObj === null || applicationObj === void 0 ? void 0 : applicationObj.submissionExist) &&
                                        ((numberOfTemplates === 1 &&
                                            isTemplateSelected(selectedTemplates)) ||
                                            (numberOfTemplates > 1 &&
                                                numberOfTemplates < maxTemplatesAllowed)) && (_jsx(WmButton, Object.assign({ id: "add-template-btn", "button-type": "textonly", icon: "f419", onClick: (event) => {
                                            addTemplateHandler(event);
                                        } }, { children: addAnotherTemplate }))) }))] }), step + i));
                    }
                    return "";
                }), _jsx("div", Object.assign({ className: "preview-application-section" }, { children: _jsx(WmButton, Object.assign({ id: "preview-app-btn", "button-type": "textonly", icon: "f208", onClick: (event) => applicationPreviewHandler(event) }, { children: translate("app.programApplications.distribute.application.build.application.preview.application.text") })) }))] })));
    const applicationSettingsCard = (_jsxs(Card, Object.assign({ classList: "external-app-settings-card", headerTitle: applicationSettingsTitle }, { children: [_jsx("h5", Object.assign({ className: "external-app-settings-header" }, { children: translate("app.programApplications.distribute.application.external.application.available.for.submissions") })), _jsxs("span", Object.assign({ className: "toggle-switch" }, { children: [_jsx("input", { id: "chk-submission-setting", type: "checkbox", checked: openSubmissions, onChange: () => {
                            setOpenSubmissions((prev) => !prev);
                        } }), _jsx("label", Object.assign({ className: "switchtoggle", htmlFor: "chk-submission-setting" }, { children: !openSubmissions ? (_jsx("span", Object.assign({ className: "toggle-text" }, { children: translate("app.programApplications.application.closed") }))) : (_jsx("span", Object.assign({ className: "toggle-text on" }, { children: translate("app.programApplications.application.open") }))) }))] })), _jsx("p", Object.assign({ className: "external-app-settings-info" }, { children: translate("app.programApplications.distribute.application.external.application.available.for.submissions.info") }))] })));
    const mainContent = () => {
        return (_jsxs(_Fragment, { children: [applicationDetailsCard(selectedType), buildApplicationCard(selectedType), !(selectedTemplates === null || selectedTemplates === void 0 ? void 0 : selectedTemplates.some((obj) => {
                    var _a;
                    return ((_a = applicationTemplateListPerOrg === null || applicationTemplateListPerOrg === void 0 ? void 0 : applicationTemplateListPerOrg.filter((temp) => temp.template.status === "ARCHIVED")) === null || _a === void 0 ? void 0 : _a.findIndex((temp) => {
                        return temp.template.uuid === obj.templateUuid;
                    })) > -1;
                })) &&
                    selectedType === "INTERNAL" && (_jsx(Card, Object.assign({ classList: "schedule-app-card", headerTitle: scheduleApplicationTitle }, { children: _jsxs("div", Object.assign({ "data-testid": "schedule-card" }, { children: [_jsx("p", Object.assign({ className: "schedule-card-infotext" }, { children: scheduleAppOrgHelpText })), scheduleUuidExists ? (_jsxs(_Fragment, { children: [activeScheduleExists && (_jsx("h5", Object.assign({ className: "schedule-label" }, { children: translate("app.programApplications.distribute.application.schedule.active.schedule") }))), !isNullOrEmpty(applicationSchedulesList) &&
                                        applicationSchedulesList.map((schedule, index) => {
                                            return (new Date(schedule.startDate) <= currentDateTime &&
                                                (new Date(schedule.endDate) >= currentDateTime ||
                                                    isNullOrEmpty(schedule.endDate)) &&
                                                renderScheduleCard(schedule, false, index, "active"));
                                        }), upcomingScheduleExists && (_jsx("h5", Object.assign({ className: "schedule-label" }, { children: translate("app.programApplications.distribute.application.schedule.upcoming.schedule") }))), !isNullOrEmpty(applicationSchedulesList) &&
                                        applicationSchedulesList.map((schedule, index) => {
                                            return (new Date(schedule.startDate) > currentDateTime &&
                                                renderScheduleCard(schedule, false, index, "future"));
                                        })] })) : (_jsxs(_Fragment, { children: [activeScheduleExists && (_jsx("h5", Object.assign({ className: "schedule-label" }, { children: translate("app.programApplications.distribute.application.schedule.active.schedule") }))), !isNullOrEmpty(applicationSchedulesList) &&
                                        applicationSchedulesList.map((schedule, index) => {
                                            return (new Date(schedule.startDate) <= currentDateTime &&
                                                (new Date(schedule.endDate) >= currentDateTime ||
                                                    isNullOrEmpty(schedule.endDate)) &&
                                                renderScheduleCard(schedule, false, index, "active"));
                                        }), upcomingScheduleExists && (_jsx("h5", Object.assign({ className: "schedule-label" }, { children: translate("app.programApplications.distribute.application.schedule.upcoming.schedule") }))), !isNullOrEmpty(applicationSchedulesList) &&
                                        applicationSchedulesList.map((schedule, index) => {
                                            return (new Date(schedule.startDate) > currentDateTime &&
                                                renderScheduleCard(schedule, false, index, "future"));
                                        })] })), _jsx(WmButton, Object.assign({ id: "schedule-app-btn", "button-type": "secondary", icon: "F419", onClick: () => {
                                    if (!isNullOrEmpty(applicationSchedulesList) &&
                                        applicationSchedulesList.find((s) => isNullOrEmpty(s.endDate))) {
                                        setOverlapModalOpen(true);
                                    }
                                    else {
                                        setScheduleApplication(true);
                                        setCurrentScheduleObj({});
                                        setScheduleApplication(true);
                                    }
                                } }, { children: scheduleAppButtonText }))] })) }))), selectedType === "EXTERNAL" && applicationSettingsCard, _jsx(SelectApplicationType, { isOpen: openTypeSelectionModal, type: "MODAL", closeModal: () => {
                        setOpenTypeSelectionModal(false);
                        focusButtonComponent("edit-app-type-btn");
                    }, selectedAudience: selectedType, primaryClick: (type) => {
                        var _a;
                        if (type !== selectedType) {
                            setSelectedType(type);
                            type === "EXTERNAL" &&
                                dispatch(getAllApplicationTemplatesForExtApp());
                            dispatch(resetApplicationSchedule());
                            setSelectedOrg("");
                            resetTemplateDropdownToInitialState();
                            setDescription("");
                            setOpenSubmissions(false);
                        }
                        setOpenTypeSelectionModal(false);
                        (_a = document.getElementById("input-application-title")) === null || _a === void 0 ? void 0 : _a.focus();
                    } })] }));
    };
    const redirectHandler = (event, isPrimaryClick) => {
        var _a;
        event.preventDefault();
        dispatch(resetFlags());
        navigate({ pathname: "/programApplications" }, {
            state: {
                appFilterValues: Object.assign({}, (_a = location.state) === null || _a === void 0 ? void 0 : _a.appFilterValues),
                currentTab: "item-1",
                focusElementId: "app-menu-" + (applicationObj === null || applicationObj === void 0 ? void 0 : applicationObj.uuid),
                previousPage: isPrimaryClick ? "distribute" : "",
            },
        });
    };
    const copyUrlModalContent = (_jsxs("div", Object.assign({ className: "copy-url-modal-body" }, { children: [_jsx("p", Object.assign({ className: "app-url" }, { children: URL })), _jsx("p", Object.assign({ className: "copy-url-info" }, { children: translate("app.programApplications.distribute.external.application.copy.url.modal.info.text") }))] })));
    return isFetchingData || (applicationUuid && !initDataFilled) ? (_jsx("div", Object.assign({ className: "loader -fullscreen" }, { children: _jsx("div", Object.assign({ className: "content content-loader", role: "alert" }, { children: "Loading..." })) }))) : (_jsxs("main", Object.assign({ className: "distribute-application-view" }, { children: [_jsxs("section", Object.assign({ className: "paper-card flex-row -justified -verticallycentered distribute-application-header" }, { children: [_jsxs("div", Object.assign({ className: "flex-row -justified -verticallycentered" }, { children: [_jsx(WmButton, { id: "distribute-close-btn", buttonType: "navigational", icon: "f156", class: "cancel hydrated", onClick: () => closeDistributeApplication(), tooltip: "Close" }), _jsxs("div", Object.assign({ className: "heading-content" }, { children: [_jsx("h1", Object.assign({ className: "header-title" }, { children: ((_b = location.state) === null || _b === void 0 ? void 0 : _b.previousPage) === "edit-menu"
                                            ? translate("app.programApplications.application.edit")
                                            : translate("app.programApplications.distribute.header.title") })), _jsxs("p", Object.assign({ className: "required-fields" }, { children: [translate("app.cohort.requiredText.prefix"), _jsx("span", Object.assign({ className: "reqd" }, { children: "*" })), translate("app.cohort.requiredText.suffix")] }))] }))] })), _jsx("div", Object.assign({ className: "flex-row -justified -verticallycentered" }, { children: _jsx(WmButton, Object.assign({ buttonType: "primary", id: "save-btn", onClick: (event) => {
                                saveHandler(event);
                            } }, { children: translate(!(applicationObj === null || applicationObj === void 0 ? void 0 : applicationObj.uuid) && selectedType === "EXTERNAL"
                                ? "app.programApplications.distribute.external.application.save.and.generate.url"
                                : "app.programApplications.distribute.header.save.button") })) }))] })), _jsx("section", Object.assign({ className: "immersive-container" }, { children: mainContent() })), _jsx("div", { className: "sr-only", id: "dialog-wrapper-top", tabIndex: 0 }), _jsx(DiscardModal, { isOpen: openDiscardModal, onPrimaryClick: (event) => discardChanges(event), onSecondaryClick: (event) => closeDiscardModalHandler(event), onClose: (event) => closeDiscardModalHandler(event), modalContent: discardModalContent, modalHeading: discardModalHeading, ariaLabelValue: discardModalAriaLabelValue, modalId: "discard-modal", contentId: "content-discard-modal", primaryText: discardPrimaryText, secondayText: discardSecondaryText }), _jsx(DiscardModal, { isOpen: openModal, onPrimaryClick: (event) => discardAllChanges(event), onSecondaryClick: (event) => closeModalHandler(event), onClose: (event) => closeModalHandler(event), modalContent: discardContent, modalHeading: discardHeading, ariaLabelValue: discardModalAriaLabelValue, modalId: "discard-modal", contentId: "content-discard-modal", primaryText: discardModalPrimaryText, secondayText: discardSecondaryText }), previewApplication && (_jsx(PreviewApplication, { setPreviewApplication: setPreviewApplication, applicationTitle: title, previewApplicationTemplatesList: previewApplicationList, source: "Application" })), scheduleApplication && (_jsx(ScheduleApplication, { setScheduleApplication: setScheduleApplication, renderScheduleCard: renderScheduleCard, scheduleObj: currentScheduleObj, currentScheduleId: scheduleId, isActiveSchedule: isActiveSchedule })), _jsx(ScheduleOverlapModal, { isOpen: overlapModalOpen, onCloseClick: () => {
                    setOverlapModalOpen(false);
                    focusButtonComponent("schedule-app-btn");
                }, renderScheduleCard: renderScheduleCard }), _jsx(DeleteModal, { isOpen: openDeleteModal ? openDeleteModal : deleteActiveSchedule, onPrimaryClick: (event) => deleteScheduleHandler(event), onSecondaryClick: () => {
                    setOpenDeleteModal(false);
                    setDeleteActiveSchedule(false);
                    focusButtonComponent("delete-schedule-" + scheduleId);
                }, modalContent: openDeleteModal ? deleteContentFuture : deleteContentActive, modalHeading: deleteModalHeading, ariaLabelValue: deleteModalAriaLabel, modalId: "delete-schedule", contentId: "content-delete-schedule" }), _jsx(DiscardModal, { isOpen: scheduleCloseModal, onPrimaryClick: (event) => updateApplicationHandler(event), onSecondaryClick: (event) => closeApplicationModal(event), onClose: (event) => closeApplicationModal(event), modalContent: closeAppModalContent, modalHeading: closeAppModalHeader, ariaLabelValue: discardModalAriaLabelValue, modalId: "close-now-modal", contentId: "content-close-now-modal", primaryText: closeAppModalPrimaryText, secondayText: closeAppModalSecondaryText }), _jsx(DiscardModal, { isOpen: openTypeChangeInfoModal, onPrimaryClick: (event) => {
                    setOpenTypeChangeInfoModal(false);
                    setOpenTypeSelectionModal(true);
                }, onSecondaryClick: (event) => {
                    setOpenTypeChangeInfoModal(false);
                    focusButtonComponent("edit-app-type-btn");
                }, onClose: (event) => {
                    setOpenTypeChangeInfoModal(false);
                    focusButtonComponent("edit-app-type-btn");
                }, modalContent: typeChangeInfoModalContent, modalHeading: typeChangeInfoModalHeading, ariaLabelValue: "", modalId: "type-change-info-modal", contentId: "content-type-change-info-modal", primaryText: typeChangeInfoModalPrimary, secondayText: typeChangeInfoModalSecondary }), _jsx("div", { className: "sr-only", id: "dialog-wrapper-bottom", tabIndex: 0 }), _jsx(WmSnackbar, { notifications: snackNotifications, id: "application-url-snack", onWmSnackbarSnackFinished: (event) => finishedSnackHandler(event) }), _jsx(WmActionModal, { isOpen: showCopyUrlModal, onPrimaryClick: (event) => {
                    navigator.clipboard.writeText(URL);
                    redirectHandler(event, true);
                }, onSecondaryClick: (event) => redirectHandler(event, false), modalContent: copyUrlModalContent, modalHeading: copyUrlModalHeading, modalType: "dialog", ariaLabelValue: copyUrlModalHeading, modalId: "copy-url-modal", contentId: "content-copy-url-modal", primaryText: copyUrlModalPrimaryText, secondayText: copyUrlModalSecondaryText, onClose: () => {
                    var _a;
                    setIsNewApp(false);
                    setShowCopyUrlModal(false);
                    (_a = document.getElementById("save-btn")) === null || _a === void 0 ? void 0 : _a.focus();
                } })] })));
};
export default DistributeApplication;
