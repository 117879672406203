var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { WmButton, WmDatepicker, WmOption, WmSelect, } from "@watermarkinsights/ripple-react";
import translate from "../../i18n/translate";
import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { getApplication, applicationSelector, getStudentApplication, saveStudentApplication, updateStudentApplication, updateStudentApplicationResponse, getApplicationExternal, saveStudentApplicationExternal, getStudentApplicationExternal, updateStudentApplicationResponseExternal, getTermsExternal, } from "./ApplicationSlice";
import { getClassByStatus, getIdByKey, getUploaderFiles, getValueByKey, } from "../common/commonActions";
import { TemplateComponentList } from "./data/TemplateComponentList";
import Inputfield from "../../features/common/component/InputField";
import { focusButtonComponent, focusUploaderComponentButton, getWordCount, isNullOrEmpty, updateClassList, urlPatternValidation, validateEmail, validatePhoneNumber, } from "../../static/js/commons.js";
import { getLoggedInUserInfoProgramApp, userSelector, } from "../../features/login/UserSlice";
import DiscardModal from "../../features/common/component/DiscardWmModal";
import { useIntl } from "react-intl";
import ResourceLink from "./template-components/ResourceLink";
import ResourceAttachment from "./template-components/ResourceAttachment";
import StudentInputLink from "./template-components/StudentInputLink";
import FileUploadComponent from "./template-components/FileUploadComponent";
import { cloneDeep, isEqual } from "lodash";
import { getCohort, cohortSelector, updateSubmission, getTerms, } from "../../features/cohorts/CohortSlice";
import { StudentApplicationStatus } from "./data/StudentApplicationStatus";
import { requirementResultStatus } from "../../features/cohorts/data/RequirementResultStatus";
import InformationModal from "../../features/common/component/InformationWmModal";
import { API_APPLICATION_ROOT, CAPTCHA_SITE_KEY } from "../../env-config";
import { format, isMatch } from "date-fns";
import Loading from "../../features/common/component/Loading";
import { Client } from "@stomp/stompjs";
import CompositeComponent, { inputFieldsDataId, } from "./template-components/CompositeComponent";
import ReCAPTCHA from "react-google-recaptcha";
import ShowSubmissionMessage from "./ShowSubmissionMessage";
const YYYYMMDD = "yyyy-MM-dd";
const MMDDYYYY = "MM/dd/yyyy";
const DATE_FORMAT_LENGTH = 10;
export default function StudentApplication({ isExternal, }) {
    var _a, _b, _c, _d, _e;
    const navigate = useNavigate();
    let { isFetchingData, termList: oTermList, showMessage, } = useSelector(applicationSelector);
    const inputRef = useRef();
    const { applicationUuid, studentApplicationUuid } = useParams();
    const [openDiscardModal, setOpenDiscardModal] = useState(false);
    const dispatch = useDispatch();
    const { studentResponse, isFetchingStudentResponseData, studentResponseSnapshot, applicationObj, invalidCaptcha, } = useSelector(applicationSelector);
    const { user, userInfoProgApp } = useSelector(userSelector);
    const { currentCohort, termList: cTermList } = useSelector(cohortSelector);
    const location = useLocation();
    let appObj = !isNullOrEmpty(applicationObj)
        ? applicationObj
        : (_a = location === null || location === void 0 ? void 0 : location.state) === null || _a === void 0 ? void 0 : _a.applicationObj;
    const [largeTextAreaWordCount, setLargeTextAreaWordCount] = useState("");
    const [isFileProcessing, setIsFileProcessing] = useState(false);
    const [snapshot, setSnapshot] = useState();
    const [admissionTerm, setAdmissionTerm] = useState("");
    const captchaRef = useRef(null);
    const u = isNullOrEmpty((_b = location === null || location === void 0 ? void 0 : location.state) === null || _b === void 0 ? void 0 : _b.student)
        ? user
        : (_c = location === null || location === void 0 ? void 0 : location.state) === null || _c === void 0 ? void 0 : _c.student;
    const [firstName, setFirstName] = useState(u ? u.firstName : "");
    const [lastName, setLastName] = useState(u ? u.lastName : "");
    const [email, setEmail] = useState(u ? u.email : "");
    const [emptyCaptcha, setEmptyCaptcha] = useState(false);
    const [closedApp, setClosedApp] = useState(false);
    const [wrongUrl, setWrongUrl] = useState(false);
    const globalLoading = useIntl().formatMessage({
        id: "global.loading",
    });
    document.title =
        ((appObj === null || appObj === void 0 ? void 0 : appObj.title) === undefined ? globalLoading : appObj === null || appObj === void 0 ? void 0 : appObj.title) +
            " | Watermark";
    const discardModalSecondaryText = useIntl().formatMessage({
        id: "app.programApplications.student.application.card.discardModal.cancel",
    });
    const discardModalPrimaryText = useIntl().formatMessage({
        id: "app.programApplications.student.application.card.discardModal.discard",
    });
    const discardModalHeadingText = useIntl().formatMessage({
        id: "app.programApplications.student.application.card.discardModal.heading",
    });
    const discardModalContentText = useIntl().formatMessage({
        id: "app.programApplications.student.application.card.discardModal.content",
    });
    const multiSelectResponseRequired = useIntl().formatMessage({
        id: "app.programApplications.student.application.multicheckbox.required.error.message",
    });
    const dropdownRequiredErrorMessage = useIntl().formatMessage({
        id: "app.programApplications.student.application.dropdown.required.error.message",
    });
    const responseRequiredErrorMessage = useIntl().formatMessage({
        id: "app.programApplications.student.application.card.response.required.error.message",
    });
    const wrongDateErrorMessage = useIntl().formatMessage({
        id: "app.programApplications.student.application.card.wrong.date.error.message",
    });
    const wrongEmailErrorMessage = useIntl().formatMessage({
        id: "app.programApplications.small.text.field.component.type.email.error.message",
    });
    const wrongPhoneNumberErrorMessage = useIntl().formatMessage({
        id: "app.programApplications.small.text.field.component.type.phone.number.error.message",
    });
    const uploaderHelpText = useIntl().formatMessage({
        id: "app.programApplications.file.uploader.help.text",
    });
    const [openApplicationDeletedModal, setOpenApplicationDeletedModal] = useState(false);
    const [openApplicationClosedModal, setOpenApplicationClosedModal] = useState(false);
    const [openApplicationUpdatedModal, setOpenApplicationUpdatedModal] = useState(false);
    const [errorField, setErrorField] = useState(new Map());
    // on load
    useEffect(() => {
        var _a, _b, _c, _d, _e;
        if (isExternal) {
            dispatch(getApplicationExternal({ applicationUuid })).then((result) => {
                var _a;
                if (result.payload == "") {
                    setWrongUrl(true);
                }
                else {
                    setClosedApp(((_a = result === null || result === void 0 ? void 0 : result.payload) === null || _a === void 0 ? void 0 : _a.status) == "CLOSED");
                }
                if (isExternal) {
                    studentApplicationUuid &&
                        dispatch(getStudentApplicationExternal({
                            applicationUuid,
                            studentApplicationUuid,
                        }));
                }
            });
            dispatch(getTermsExternal());
        }
        else {
            let orgUuid = localStorage.getItem("orgUuid");
            dispatch(getApplication({ applicationUuid })).then(() => {
                dispatch(getStudentApplication({ applicationUuid }));
            });
            if (!userInfoProgApp.applicationsUser) {
                dispatch(getLoggedInUserInfoProgramApp());
            }
            if (!isNullOrEmpty(orgUuid) &&
                !isNullOrEmpty((_b = (_a = location.state) === null || _a === void 0 ? void 0 : _a.currentProgramData) === null || _b === void 0 ? void 0 : _b.uuid) &&
                !isNullOrEmpty((_c = location.state) === null || _c === void 0 ? void 0 : _c.cohortUuid)) {
                dispatch(getCohort({
                    orgUuid: orgUuid,
                    programUuid: location.state.currentProgramData.uuid,
                    cohortUuid: location.state.cohortUuid,
                }));
            }
            if (!isNullOrEmpty(orgUuid) &&
                ((_e = (_d = userInfoProgApp === null || userInfoProgApp === void 0 ? void 0 : userInfoProgApp.sllUser) === null || _d === void 0 ? void 0 : _d.available_apps) === null || _e === void 0 ? void 0 : _e.includes("gateways"))) {
                dispatch(getTerms({
                    orgUuid: orgUuid,
                }));
            }
        }
    }, []);
    const loadFunc = () => {
        dispatch(getApplication({ applicationUuid }));
        if (!userInfoProgApp.applicationsUser) {
            dispatch(getLoggedInUserInfoProgramApp());
        }
        setOpenApplicationUpdatedModal(false);
    };
    useEffect(() => {
        if (applicationObj) {
            const client = new Client({
                brokerURL: `${API_APPLICATION_ROOT.replace("https", "wss").replace("http", "ws")}application-websocket`,
            });
            const connectCallback = () => {
                var _a;
                console.log("Connected");
                client.subscribe(`/topic/applications/${applicationObj.uuid}`, (message) => {
                    console.log("Received Message: ", message.body);
                    let obj = JSON.parse(message.body);
                    if (obj && obj.message === "deleted") {
                        setOpenApplicationDeletedModal(true);
                    }
                    else if (obj && obj.message === "closed") {
                        setOpenApplicationClosedModal(true);
                    }
                });
                (_a = applicationObj === null || applicationObj === void 0 ? void 0 : applicationObj.applicationAppTemplates) === null || _a === void 0 ? void 0 : _a.forEach((temp) => {
                    let topic = `/topic/templates/${temp.applicationTemplate.uuid}`;
                    console.log("Subscribed to " + topic);
                    client.subscribe(topic, (message) => {
                        console.log("Received Message: ", message.body);
                        let obj = JSON.parse(message.body);
                        if (obj && obj.message === "updated") {
                            setOpenApplicationUpdatedModal(true);
                        }
                    });
                });
            };
            const errorCallback = (error) => {
                console.error("Error connecting to websocket: ", error);
            };
            client.onConnect = connectCallback;
            client.onStompError = errorCallback;
            client.activate();
            return () => {
                client === null || client === void 0 ? void 0 : client.forceDisconnect();
            };
        }
    }, [applicationObj]);
    const discardChanges = (event) => {
        event.preventDefault();
        setOpenDiscardModal(false);
        if (location.state.sourcePage === "StudentLicensurePlanView") {
            redirectToLP();
        }
        else {
            navigate({
                pathname: "/programApplications",
            });
            setTimeout(() => {
                var _a;
                if (appObj) {
                    (_a = document.getElementById("application-title-" + (appObj === null || appObj === void 0 ? void 0 : appObj.uuid))) === null || _a === void 0 ? void 0 : _a.focus();
                }
                else {
                    focusButtonComponent("submit-application");
                }
            }, 2000);
        }
    };
    const redirectToLP = () => {
        navigate({
            pathname: `/timeline/${location.state.timelineUuid}/cohort/${location.state.cohortUuid}/student/${location.state.studentUuid}`,
        }, {
            state: {
                selectedUuid: location.state.selectedUuid,
                currentCohort: location.state.currentCohort,
                student: location.state.student,
                isStudentOverview: location.state.isStudentOverview,
                fromStudentLandingPage: location.state.fromStudentLandingPage,
                currentProgramData: location.state.currentProgramData,
                programUuid: location.state.currentProgramData.uuid,
                fromProgramApplication: location.state.fromProgramApplication,
            },
        });
        setTimeout(() => {
            var _a;
            (_a = document
                .getElementById("req-button-id-" +
                location.state.userRequirementResultReference.requirementUuid)) === null || _a === void 0 ? void 0 : _a.focus();
        }, 1000);
    };
    const cancelApplicationHandler = (event, forceExit = false) => {
        var _a;
        event.preventDefault();
        if (((_a = studentResponse.responses) === null || _a === void 0 ? void 0 : _a.length) > 0) {
            if (!forceExit && !isEqual(studentResponse, studentResponseSnapshot)) {
                setOpenDiscardModal(true);
            }
            else {
                if (location.state.sourcePage === "StudentLicensurePlanView") {
                    redirectToLP();
                }
                else {
                    navigate({
                        pathname: "/programApplications",
                    });
                    setTimeout(() => {
                        var _a;
                        if (appObj && (studentResponse === null || studentResponse === void 0 ? void 0 : studentResponse.status)) {
                            (_a = document
                                .getElementById("application-title-" + (appObj === null || appObj === void 0 ? void 0 : appObj.uuid))) === null || _a === void 0 ? void 0 : _a.focus();
                        }
                        else {
                            focusButtonComponent("submit-application");
                        }
                    }, 2000);
                }
            }
        }
        else {
            if (location.state.sourcePage === "StudentLicensurePlanView") {
                redirectToLP();
            }
            else {
                navigate({
                    pathname: "/programApplications",
                });
                setTimeout(() => {
                    var _a;
                    if (appObj) {
                        (_a = document
                            .getElementById("application-title-" + (appObj === null || appObj === void 0 ? void 0 : appObj.uuid))) === null || _a === void 0 ? void 0 : _a.focus();
                    }
                    else {
                        focusButtonComponent("submit-application");
                    }
                }, 2000);
            }
        }
    };
    useEffect(() => {
        if (studentResponse && isExternal) {
            setFirstName(studentResponse.firstName);
            setLastName(studentResponse.lastName);
            setEmail(studentResponse.email);
        }
    }, [studentResponse]);
    useEffect(() => {
        var _a;
        setAdmissionTerm(!isNullOrEmpty(studentResponse === null || studentResponse === void 0 ? void 0 : studentResponse.admissionTermUuid)
            ? studentResponse === null || studentResponse === void 0 ? void 0 : studentResponse.admissionTermUuid
            : (_a = currentCohort === null || currentCohort === void 0 ? void 0 : currentCohort.entryTermObject) === null || _a === void 0 ? void 0 : _a.uuid);
    }, [studentResponse, currentCohort]);
    const closeDiscardModalHandler = (event) => {
        event.preventDefault();
        setOpenDiscardModal(false);
        setTimeout(() => {
            focusButtonComponent("cancel-btn");
        }, 200);
    };
    const updateAdmissionTerm = (admissionTerm) => {
        setAdmissionTerm(admissionTerm);
        dispatch(updateStudentApplication({ admissionTermUuid: admissionTerm }));
    };
    const updateFirstName = (firstName) => {
        setFirstName(firstName);
        dispatch(updateStudentApplication({ firstName, lastName, email }));
    };
    const updateLastName = (lastName) => {
        setLastName(lastName);
        dispatch(updateStudentApplication({ firstName, lastName, email }));
    };
    const updateEmail = (email) => {
        setEmail(email);
        dispatch(updateStudentApplication({ firstName, lastName, email }));
    };
    const updateResponse = (templateUuid, templateComponentUuid, responseText, multiselectOptions) => {
        setLargeTextAreaWordCount(responseText);
        dispatch(updateStudentApplication({
            templateUuid,
            templateComponentUuid,
            responseText,
            multiselectOptions,
        }));
    };
    const findUrlValue = (templateUuid, templateComponentUuid, isUrl) => {
        if (studentResponse.responses) {
            for (let x of studentResponse.responses) {
                if (x.templateUuid === templateUuid &&
                    x.templateComponentUuid === templateComponentUuid) {
                    return isUrl ? x.responseText : x.linkTitle;
                }
            }
        }
        return "";
    };
    const updateLargeTextAreaHandler = (templateUuid, templateComponentUuid, responseText, maxLimit) => {
        let wordCounts = getWordCount(responseText);
        if (wordCounts > maxLimit) {
            return;
        }
        setLargeTextAreaWordCount(responseText);
        dispatch(updateStudentApplication({
            templateUuid,
            templateComponentUuid,
            responseText,
        }));
    };
    const findValue = (templateUuid, templateComponentUuid) => {
        if (studentResponse.responses) {
            for (let x of studentResponse.responses) {
                if (x.templateUuid === templateUuid &&
                    x.templateComponentUuid === templateComponentUuid)
                    return x.responseText;
            }
        }
        return "";
    };
    const findResponse = (templateUuid, templateComponentUuid) => {
        if (studentResponse.responses)
            for (let x of studentResponse.responses) {
                if (x.templateUuid === templateUuid &&
                    x.templateComponentUuid === templateComponentUuid)
                    return x;
            }
        return undefined;
    };
    const updateAcknowledgementHandler = (templateUuid, templateComponentUuid, event) => {
        let isAcknowledged = event.target.checked;
        dispatch(updateStudentApplication({
            templateUuid,
            templateComponentUuid,
            isAcknowledged,
        }));
    };
    const findAckCheckboxValue = (templateUuid, templateComponentUuid) => {
        if (studentResponse.responses)
            for (let x of studentResponse.responses) {
                if (x.templateUuid === templateUuid &&
                    x.templateComponentUuid === templateComponentUuid)
                    return x.isAcknowledged;
            }
        return false;
    };
    const requestBody = (status) => {
        var _a, _b, _c, _d;
        let responses = cloneDeep(studentResponse.responses);
        responses === null || responses === void 0 ? void 0 : responses.map((res) => {
            var _a;
            res.responseText = (_a = res.responseText) === null || _a === void 0 ? void 0 : _a.trim();
            return res;
        });
        return {
            studentUuid: isExternal ? null : userInfoProgApp === null || userInfoProgApp === void 0 ? void 0 : userInfoProgApp.applicationsUser.uuid,
            status: status,
            userRequirementResultUuid: ((_a = location === null || location === void 0 ? void 0 : location.state) === null || _a === void 0 ? void 0 : _a.userRequirementResultReference)
                ? location.state.userRequirementResultReference.uuid
                : null,
            response: Array.isArray(studentResponse.responses) ? [...responses] : [],
            firstName: (_b = studentResponse === null || studentResponse === void 0 ? void 0 : studentResponse.firstName) === null || _b === void 0 ? void 0 : _b.trim(),
            lastName: (_c = studentResponse === null || studentResponse === void 0 ? void 0 : studentResponse.lastName) === null || _c === void 0 ? void 0 : _c.trim(),
            email: (_d = studentResponse === null || studentResponse === void 0 ? void 0 : studentResponse.email) === null || _d === void 0 ? void 0 : _d.trim(),
        };
    };
    const isOptionsSelected = (templateUuid, appCompUuid, optionUuid) => {
        let resp = findResponse(templateUuid, appCompUuid);
        if (resp) {
            return (resp.multiselectOptions.findIndex((opt) => opt.uuid === optionUuid) > -1);
        }
        return false;
    };
    const updateErrorMap = (errorData, applicationComponent, uuid) => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        const key = `${(_a = applicationComponent === null || applicationComponent === void 0 ? void 0 : applicationComponent.componentType) === null || _a === void 0 ? void 0 : _a.toLowerCase()}${uuid}${applicationComponent === null || applicationComponent === void 0 ? void 0 : applicationComponent.uuid}`;
        setErrorField((map) => new Map(map.set(key, errorData)));
        if (["RADIO_BUTTON", "MULTISELECT_CHECKBOXES"].includes(applicationComponent === null || applicationComponent === void 0 ? void 0 : applicationComponent.componentType)) {
            let optionfocusId = (_b = applicationComponent === null || applicationComponent === void 0 ? void 0 : applicationComponent.componentOptionList[0]) === null || _b === void 0 ? void 0 : _b.uuid;
            (_c = document.getElementById("option-" + optionfocusId)) === null || _c === void 0 ? void 0 : _c.focus();
        }
        else if ((applicationComponent === null || applicationComponent === void 0 ? void 0 : applicationComponent.componentType) === "SMALL_TEXT_FIELD") {
            (_d = document.getElementById("input-" + (applicationComponent === null || applicationComponent === void 0 ? void 0 : applicationComponent.uuid))) === null || _d === void 0 ? void 0 : _d.focus();
        }
        else if ((applicationComponent === null || applicationComponent === void 0 ? void 0 : applicationComponent.componentType) === "LARGE_TEXT_AREA") {
            (_e = document
                .getElementById("largeTxt-" + (applicationComponent === null || applicationComponent === void 0 ? void 0 : applicationComponent.uuid))) === null || _e === void 0 ? void 0 : _e.focus();
        }
        else if ((applicationComponent === null || applicationComponent === void 0 ? void 0 : applicationComponent.componentType) === "DROPDOWN") {
            (_f = document
                .getElementById("select-dropdown-" + (applicationComponent === null || applicationComponent === void 0 ? void 0 : applicationComponent.uuid))) === null || _f === void 0 ? void 0 : _f.focus();
        }
        else if ((applicationComponent === null || applicationComponent === void 0 ? void 0 : applicationComponent.componentType) === "FILE_UPLOAD") {
            focusUploaderComponentButton("file-upload-" + (applicationComponent === null || applicationComponent === void 0 ? void 0 : applicationComponent.uuid));
        }
        else if ((applicationComponent === null || applicationComponent === void 0 ? void 0 : applicationComponent.componentType) === "LINK_INPUT") {
            (_g = document
                .getElementById("input-" +
                getIdByKey(applicationComponent === null || applicationComponent === void 0 ? void 0 : applicationComponent.componentType, TemplateComponentList) +
                "-url-" +
                (applicationComponent === null || applicationComponent === void 0 ? void 0 : applicationComponent.uuid))) === null || _g === void 0 ? void 0 : _g.focus();
        }
        else if ((applicationComponent === null || applicationComponent === void 0 ? void 0 : applicationComponent.componentType) === "DEFAULT_COMPONENT") {
            if (document.getElementById("input-" + uuid + false))
                (_h = document.getElementById("input-" + uuid + false)) === null || _h === void 0 ? void 0 : _h.focus();
            else
                (_j = document.getElementById("term-dropdown-" + uuid)) === null || _j === void 0 ? void 0 : _j.focus();
        }
        else {
            (_k = document
                .getElementById((applicationComponent === null || applicationComponent === void 0 ? void 0 : applicationComponent.componentType.toLowerCase()) +
                uuid +
                (applicationComponent === null || applicationComponent === void 0 ? void 0 : applicationComponent.uuid))) === null || _k === void 0 ? void 0 : _k.focus();
        }
    };
    const saveOrUpdateStudentApplication = (event, draft) => {
        var _a, _b, _c, _d;
        event.preventDefault();
        let errorData = false;
        let flag = false;
        let focusComponent = {};
        let templateUuid;
        (_a = appObj === null || appObj === void 0 ? void 0 : appObj.applicationAppTemplates) === null || _a === void 0 ? void 0 : _a.map((appTemplate, index) => {
            var _a;
            if (isExternal && index == 0) {
                if (isNullOrEmpty(firstName === null || firstName === void 0 ? void 0 : firstName.trim())) {
                    updateErrorMap(true, appTemplate.applicationTemplate.templateComponentList.filter((x) => x.componentType == "DEFAULT_COMPONENT")[0], inputFieldsDataId.firstName);
                    if (isNullOrEmpty(focusComponent === null || focusComponent === void 0 ? void 0 : focusComponent.componentType)) {
                        focusComponent =
                            appTemplate.applicationTemplate.templateComponentList.filter((x) => x.componentType == "DEFAULT_COMPONENT")[0];
                        templateUuid = inputFieldsDataId.firstName;
                    }
                }
                if (isNullOrEmpty(lastName === null || lastName === void 0 ? void 0 : lastName.trim())) {
                    updateErrorMap(true, appTemplate.applicationTemplate.templateComponentList.filter((x) => x.componentType == "DEFAULT_COMPONENT")[0], inputFieldsDataId.lastName);
                    if (isNullOrEmpty(focusComponent === null || focusComponent === void 0 ? void 0 : focusComponent.componentType)) {
                        focusComponent =
                            appTemplate.applicationTemplate.templateComponentList.filter((x) => x.componentType == "DEFAULT_COMPONENT")[0];
                        templateUuid = inputFieldsDataId.lastName;
                    }
                }
                if (!validateEmail(email === null || email === void 0 ? void 0 : email.trim())) {
                    updateErrorMap(true, appTemplate.applicationTemplate.templateComponentList.filter((x) => x.componentType == "DEFAULT_COMPONENT")[0], inputFieldsDataId.email);
                    if (isNullOrEmpty(focusComponent === null || focusComponent === void 0 ? void 0 : focusComponent.componentType)) {
                        focusComponent =
                            appTemplate.applicationTemplate.templateComponentList.filter((x) => x.componentType == "DEFAULT_COMPONENT")[0];
                        templateUuid = inputFieldsDataId.email;
                    }
                }
                errorData =
                    isNullOrEmpty(firstName === null || firstName === void 0 ? void 0 : firstName.trim()) ||
                        isNullOrEmpty(lastName === null || lastName === void 0 ? void 0 : lastName.trim()) ||
                        !validateEmail(email === null || email === void 0 ? void 0 : email.trim());
            }
            (_a = appTemplate.applicationTemplate.templateComponentList) === null || _a === void 0 ? void 0 : _a.map((applicationComponent) => {
                var _a, _b, _c, _d;
                flag = false;
                if (studentResponse.responses) {
                    for (let x of studentResponse.responses) {
                        if (x.templateUuid === appTemplate.applicationTemplate.uuid &&
                            x.templateComponentUuid === applicationComponent.uuid) {
                            if (isNullOrEmpty((_a = x.responseText) === null || _a === void 0 ? void 0 : _a.trim()) &&
                                !applicationComponent.isMandatory) {
                                continue;
                            }
                            flag = true;
                            if (applicationComponent.componentType == "RADIO_BUTTON" ||
                                applicationComponent.componentType == "MULTISELECT_CHECKBOXES") {
                                let resp = findResponse(appTemplate.applicationTemplate.uuid, applicationComponent.uuid);
                                if (resp && ((_b = resp === null || resp === void 0 ? void 0 : resp.multiselectOptions) === null || _b === void 0 ? void 0 : _b.length) > 0) {
                                    continue;
                                }
                                else {
                                    if (!draft) {
                                        errorData = true;
                                    }
                                }
                            }
                            else if (applicationComponent.componentType === "DATE_SELECTOR") {
                                const id = applicationComponent.componentType.toLowerCase() +
                                    appTemplate.applicationTemplate.uuid +
                                    applicationComponent.uuid;
                                const isValid = !isNullOrEmpty(x.responseText) &&
                                    x.responseText.length === DATE_FORMAT_LENGTH &&
                                    isMatch(x.responseText, YYYYMMDD) &&
                                    isNullOrEmpty((_c = document.getElementById(id)) === null || _c === void 0 ? void 0 : _c.getAttribute("error-message"));
                                errorData = !Boolean(isValid || draft);
                            }
                            else if (applicationComponent.componentType ===
                                "ACKNOWLEDGEMENT_CHECKBOX") {
                                errorData = isNullOrEmpty(x.isAcknowledged) && !draft;
                            }
                            else if (applicationComponent.componentType === "FILE_UPLOAD") {
                                errorData = ((_d = x.files) === null || _d === void 0 ? void 0 : _d.length) === 0 && !draft;
                            }
                            else if (applicationComponent.componentType === "SMALL_TEXT_FIELD") {
                                if (applicationComponent.smallTextType == "EMAIL") {
                                    errorData =
                                        (isNullOrEmpty(x.responseText.trim()) ||
                                            !validateEmail(x.responseText.trim())) &&
                                            !draft;
                                }
                                else if (applicationComponent.smallTextType == "PHONE_NUMBER") {
                                    errorData =
                                        (isNullOrEmpty(x.responseText.trim()) ||
                                            !validatePhoneNumber(x.responseText.trim())) &&
                                            !draft;
                                }
                                else {
                                    errorData = isNullOrEmpty(x.responseText.trim()) && !draft;
                                }
                            }
                            else if (applicationComponent.componentType === "LINK_INPUT") {
                                errorData =
                                    !isNullOrEmpty(x.responseText.trim()) &&
                                        urlPatternValidation(x.responseText.trim())
                                        ? false
                                        : draft
                                            ? false
                                            : true;
                            }
                            else {
                                errorData = isNullOrEmpty(x.responseText.trim()) && !draft;
                            }
                            if (!draft) {
                                updateErrorMap(errorData, applicationComponent, appTemplate.applicationTemplate.uuid);
                            }
                        }
                    }
                    if (applicationComponent.isMandatory && !flag && !draft) {
                        errorData = true;
                        updateErrorMap(errorData, applicationComponent, appTemplate.applicationTemplate.uuid);
                    }
                }
                else if (applicationComponent.isMandatory && !draft) {
                    errorData = true;
                    updateErrorMap(errorData, applicationComponent, appTemplate.applicationTemplate.uuid);
                }
                if (isNullOrEmpty(focusComponent === null || focusComponent === void 0 ? void 0 : focusComponent.componentType) && errorData) {
                    focusComponent = applicationComponent;
                    templateUuid = appTemplate.applicationTemplate.uuid;
                }
            });
            return appTemplate;
        });
        if (!draft && !isNullOrEmpty(focusComponent === null || focusComponent === void 0 ? void 0 : focusComponent.componentType)) {
            setTimeout(() => {
                updateErrorMap(true, focusComponent, templateUuid);
            }, 10);
            return;
        }
        if (isExternal) {
            if (isNullOrEmpty((_b = captchaRef.current) === null || _b === void 0 ? void 0 : _b.getValue())) {
                setEmptyCaptcha(true);
                const captchaDiv = document.getElementsByClassName("captcha-div")[0];
                if (captchaDiv) {
                    captchaDiv.focus();
                    captchaDiv.scrollIntoView({ behavior: "smooth" });
                }
                return;
            }
        }
        const updateAndRedirectToLP = (studentApplicationUuid) => {
            dispatch(updateSubmission({
                note: location.state.userRequirementResultReference.reviewNote,
                status: draft ? "NOT_SUBMITTED_DRAFT" : "AWAITING_REVIEW",
                timelineUuid: location.state.timelineUuid,
                requirementUuid: location.state.userRequirementResultReference.requirementUuid,
                submissionUuid: location.state.userRequirementResultReference.uuid,
                studentAck: false,
                studentApplicationUuid,
            })).then(() => {
                redirectToLP();
            });
        };
        let valid = true;
        if (isFetchingData) {
            return;
        }
        (_c = appObj === null || appObj === void 0 ? void 0 : appObj.applicationAppTemplates) === null || _c === void 0 ? void 0 : _c.forEach((appTemplate) => {
            var _a;
            return (_a = appTemplate.applicationTemplate.templateComponentList) === null || _a === void 0 ? void 0 : _a.forEach((applicationComponent) => {
                var _a;
                if (applicationComponent.componentType === "LINK_INPUT") {
                    let urlValue = findUrlValue(appTemplate.applicationTemplate.uuid, applicationComponent.uuid, true);
                    if (valid &&
                        !isNullOrEmpty(urlValue) &&
                        !urlPatternValidation(urlValue)) {
                        valid = false;
                        (_a = document
                            .getElementById("input-link-input-url-" + applicationComponent.uuid)) === null || _a === void 0 ? void 0 : _a.focus();
                    }
                }
            });
        });
        if (!valid) {
            return;
        }
        if (studentResponse.uuid) {
            dispatch(isExternal
                ? updateStudentApplicationResponseExternal({
                    applicationUuid: appObj === null || appObj === void 0 ? void 0 : appObj.uuid,
                    responseUuid: studentResponse.uuid,
                    payload: draft
                        ? requestBody("DRAFT")
                        : requestBody("AWAITING_REVIEW"),
                })
                : updateStudentApplicationResponse({
                    applicationUuid: appObj === null || appObj === void 0 ? void 0 : appObj.uuid,
                    responseUuid: studentResponse.uuid,
                    payload: draft
                        ? requestBody("DRAFT")
                        : requestBody("AWAITING_REVIEW"),
                })).then(() => {
                var _a;
                if (isExternal) {
                    (_a = captchaRef === null || captchaRef === void 0 ? void 0 : captchaRef.current) === null || _a === void 0 ? void 0 : _a.reset();
                }
                else if (location.state.sourcePage === "StudentLicensurePlanView") {
                    redirectToLP();
                }
                else {
                    navigate({
                        pathname: "/programApplications",
                    });
                    setTimeout(() => {
                        var _a;
                        if (appObj) {
                            (_a = document
                                .getElementById("application-title-" + (appObj === null || appObj === void 0 ? void 0 : appObj.uuid))) === null || _a === void 0 ? void 0 : _a.focus();
                        }
                    }, 2000);
                }
            });
        }
        else {
            dispatch(isExternal
                ? saveStudentApplicationExternal({
                    applicationUuid: appObj === null || appObj === void 0 ? void 0 : appObj.uuid,
                    payload: requestBody("AWAITING_REVIEW"),
                    captchaToken: (_d = captchaRef === null || captchaRef === void 0 ? void 0 : captchaRef.current) === null || _d === void 0 ? void 0 : _d.getValue(),
                })
                : saveStudentApplication({
                    applicationUuid: appObj === null || appObj === void 0 ? void 0 : appObj.uuid,
                    payload: draft
                        ? requestBody("DRAFT")
                        : requestBody("AWAITING_REVIEW"),
                })).then((resData) => {
                var _a;
                if (isExternal) {
                    if ((resData === null || resData === void 0 ? void 0 : resData.payload) == "application_not_found") {
                        setWrongUrl(true);
                    }
                    else if ((resData === null || resData === void 0 ? void 0 : resData.payload) == "application_closed") {
                        setClosedApp(true);
                    }
                    (_a = captchaRef === null || captchaRef === void 0 ? void 0 : captchaRef.current) === null || _a === void 0 ? void 0 : _a.reset();
                }
                else if (location.state.sourcePage === "StudentLicensurePlanView") {
                    redirectToLP();
                }
                else {
                    navigate({
                        pathname: "/programApplications",
                    });
                    setTimeout(() => {
                        var _a;
                        if (appObj) {
                            (_a = document
                                .getElementById("application-title-" + (appObj === null || appObj === void 0 ? void 0 : appObj.uuid))) === null || _a === void 0 ? void 0 : _a.focus();
                        }
                    }, 2000);
                }
            });
        }
    };
    let urrLp = (_d = location === null || location === void 0 ? void 0 : location.state) === null || _d === void 0 ? void 0 : _d.userRequirementResultReference;
    const showErrorMessage = (applicationComponent, uuid) => {
        if (errorField.get(applicationComponent.componentType.toLowerCase() +
            uuid +
            applicationComponent.uuid)) {
            return (_jsx("span", Object.assign({ className: "error input-error" }, { children: applicationComponent.componentType === "MULTISELECT_CHECKBOXES"
                    ? multiSelectResponseRequired
                    : responseRequiredErrorMessage })));
        }
        return "";
    };
    const renderHeader = () => {
        var _a, _b, _c, _d, _e, _f;
        if (((_a = location === null || location === void 0 ? void 0 : location.state) === null || _a === void 0 ? void 0 : _a.sourcePage) !== undefined) {
            return (_jsxs(_Fragment, { children: [_jsxs("section", Object.assign({ className: `header flex-row -justified -verticallycentered ${appObj.status === "CLOSED" && studentResponse.status === "DRAFT"
                            ? "archived-app"
                            : ""}` }, { children: [_jsxs("div", Object.assign({ className: "flex-row -justified -verticallycentered" }, { children: [_jsx(WmButton, { className: "cancel-btn", buttonType: "navigational", icon: "close", tooltip: "Cancel", tooltipPosition: "bottom", id: "cancel-btn", class: "hydrated", onClick: cancelApplicationHandler }), _jsx("h1", Object.assign({ className: "student-application-title" }, { children: appObj === null || appObj === void 0 ? void 0 : appObj.title }))] })), _jsxs("div", Object.assign({ className: "flex-row -verticallycentered" }, { children: [_jsxs("div", Object.assign({ className: "status flex-row -verticallycentered -status-tag" }, { children: [_jsx("div", Object.assign({ className: (urrLp === null || urrLp === void 0 ? void 0 : urrLp.status)
                                                    ? getClassByStatus(urrLp.status)
                                                    : getClassByStatus(studentResponse.status) }, { children: urrLp
                                                    ? getValueByKey(urrLp.status, requirementResultStatus)
                                                    : (studentResponse.status === "DRAFT" ||
                                                        studentResponse.status === undefined) &&
                                                        location.state.sourcePage === "StudentLicensurePlanView"
                                                        ? "NOT SUBMITTED"
                                                        : getValueByKey(studentResponse.status, StudentApplicationStatus) })), (urrLp === null || urrLp === void 0 ? void 0 : urrLp.conditionallyMet) && (urrLp === null || urrLp === void 0 ? void 0 : urrLp.status) === "MET" && (_jsx("span", Object.assign({ className: "draft-label" }, { children: "Conditionally Met" })))] })), (studentResponse &&
                                        studentResponse.uuid &&
                                        studentResponse.status === "AWAITING_REVIEW") ||
                                        applicationObj.connectedToArchivedLP ? (_jsx(WmButton, Object.assign({ buttonType: "primary", id: "close-btn", onClick: (event) => {
                                            if (location.state.sourcePage === "StudentLicensurePlanView") {
                                                redirectToLP();
                                            }
                                            else {
                                                navigate({
                                                    pathname: "/programApplications",
                                                });
                                                setTimeout(() => {
                                                    var _a;
                                                    (_a = document
                                                        .getElementById("application-title-" + (appObj === null || appObj === void 0 ? void 0 : appObj.uuid))) === null || _a === void 0 ? void 0 : _a.focus();
                                                }, 2000);
                                            }
                                        }, disabled: isFileProcessing }, { children: translate("app.programApplications.close.btn") }))) : (studentResponse.status === "DRAFT" &&
                                        ((_b = studentResponse === null || studentResponse === void 0 ? void 0 : studentResponse.application) === null || _b === void 0 ? void 0 : _b.status) === "OPEN") ||
                                        isNullOrEmpty(studentResponse === null || studentResponse === void 0 ? void 0 : studentResponse.status) ? (_jsxs("div", Object.assign({ className: "flex-row -justified -verticallycentered" }, { children: [_jsx(WmButton, Object.assign({ buttonType: "secondary", id: "save-as-draft-btn", onClick: (event) => {
                                                    saveOrUpdateStudentApplication(event, true);
                                                }, disabled: isFileProcessing }, { children: translate("app.programApplications.saveAsDraft.btn") })), _jsx(WmButton, Object.assign({ buttonType: "primary", id: "submit-btn", onClick: (event) => {
                                                    saveOrUpdateStudentApplication(event, false);
                                                }, disabled: isFileProcessing }, { children: translate("app.programApplications.student.application.submit") }))] }))) : urrLp || (studentResponse && studentResponse.uuid) ? ((((urrLp === null || urrLp === void 0 ? void 0 : urrLp.status) === "NOT_MET" &&
                                        ((_c = studentResponse === null || studentResponse === void 0 ? void 0 : studentResponse.application) === null || _c === void 0 ? void 0 : _c.status) === "OPEN" &&
                                        studentResponse.allowResubmission) ||
                                        ((urrLp === null || urrLp === void 0 ? void 0 : urrLp.conditionallyMet) &&
                                            (urrLp === null || urrLp === void 0 ? void 0 : urrLp.status) === "MET" &&
                                            ((_d = studentResponse === null || studentResponse === void 0 ? void 0 : studentResponse.application) === null || _d === void 0 ? void 0 : _d.status) === "OPEN" &&
                                            studentResponse.allowResubmission) ||
                                        (studentResponse.status === "CONDITIONALLY_ACCEPTED" &&
                                            studentResponse.allowResubmission &&
                                            ((_e = studentResponse === null || studentResponse === void 0 ? void 0 : studentResponse.application) === null || _e === void 0 ? void 0 : _e.status) === "OPEN") ||
                                        (studentResponse.status === "DENIED" &&
                                            studentResponse.allowResubmission &&
                                            ((_f = studentResponse === null || studentResponse === void 0 ? void 0 : studentResponse.application) === null || _f === void 0 ? void 0 : _f.status) === "OPEN")) &&
                                        !applicationObj.connectedToArchivedLP ? (_jsxs("div", Object.assign({ className: "flex-row -justified -verticallycentered" }, { children: [_jsx(WmButton, Object.assign({ buttonType: "secondary", id: "save-as-draft-btn", onClick: (event) => {
                                                    saveOrUpdateStudentApplication(event, true);
                                                }, disabled: isFileProcessing }, { children: translate("app.programApplications.saveAsDraft.btn") })), _jsx(WmButton, Object.assign({ buttonType: "primary", id: "resubmit-btn", onClick: (event) => {
                                                    saveOrUpdateStudentApplication(event, false);
                                                }, disabled: isFileProcessing }, { children: translate("app.programApplications.student.application.resubmit") }))] }))) : (_jsx(WmButton, Object.assign({ buttonType: "primary", id: "close-btn", onClick: () => {
                                            if (location.state.sourcePage === "StudentLicensurePlanView") {
                                                redirectToLP();
                                            }
                                            else {
                                                navigate({
                                                    pathname: "/programApplications",
                                                });
                                                setTimeout(() => {
                                                    var _a;
                                                    (_a = document
                                                        .getElementById("application-title-" + (appObj === null || appObj === void 0 ? void 0 : appObj.uuid))) === null || _a === void 0 ? void 0 : _a.focus();
                                                }, 2000);
                                            }
                                        }, disabled: isFileProcessing }, { children: translate("app.programApplications.close.btn") })))) : null] }))] })), appObj.status === "CLOSED" && studentResponse.status === "DRAFT" && (_jsx("div", Object.assign({ className: "preview-info-content" }, { children: _jsx("span", Object.assign({ className: "preview-info-text" }, { children: translate("app.programApplications.application.student.archived.app.text") })) })))] }));
        }
        else if (isExternal) {
            return (_jsx("section", { children: _jsxs("div", Object.assign({ className: "header flex-row -justified -verticallycentered" }, { children: [_jsx("h1", Object.assign({ className: "student-application-title" }, { children: translate("app.programApplications.external.student.application.complete.application") })), !studentApplicationUuid && (_jsx(WmButton, Object.assign({ buttonType: "primary", id: "submit-btn", onClick: (event) => {
                                saveOrUpdateStudentApplication(event, false);
                            }, disabled: isFileProcessing }, { children: translate("app.programApplications.student.application.submit") }))), studentApplicationUuid &&
                            studentResponse.status === "CONDITIONALLY_ACCEPTED" &&
                            studentResponse.allowResubmission && (_jsx(WmButton, Object.assign({ buttonType: "primary", id: "resubmit-btn", onClick: (event) => {
                                saveOrUpdateStudentApplication(event, false);
                            }, disabled: isFileProcessing }, { children: translate("app.programApplications.student.application.resubmit") })))] })) }));
        }
        else {
            return (_jsxs("section", { children: [_jsx("div", Object.assign({ className: "header previewheader" }, { children: _jsx("h1", Object.assign({ className: "student-application-title" }, { children: translate("app.programApplications.distribute.application.build.application.preview.application.text") })) })), _jsx("div", Object.assign({ className: "preview-info-content" }, { children: _jsx("span", Object.assign({ className: "preview-info-text" }, { children: translate("app.programApplications.view.application.flyout.info.text") })) }))] }));
        }
    };
    let deleteModalHeading = useIntl().formatMessage({
        id: "app.studentApplication.application.deleted.heading",
    }, {
        title: applicationObj.title,
    });
    let deleteModalContent = useIntl().formatMessage({
        id: "app.studentApplication.application.deleted.content",
    });
    let deleteModalAriaLabel = useIntl().formatMessage({
        id: "app.studentApplication.application.deleted.aria.label",
    });
    const renderDeleteApplicationModal = (isOpen) => {
        let modalHeading = deleteModalHeading;
        let modalContent = deleteModalContent;
        let ariaLabelValue = deleteModalAriaLabel;
        return (_jsx(InformationModal, { isOpen: isOpen, onPrimaryClick: (event) => {
                if (isExternal) {
                    event.preventDefault();
                    setOpenApplicationDeletedModal(false);
                    setWrongUrl(true);
                }
                else {
                    cancelApplicationHandler(event, true);
                }
            }, modalContent: modalContent, modalHeading: modalHeading, ariaLabelValue: ariaLabelValue, modalId: "information-modal-deleted", contentId: "content-information-modal-deleted", primaryButtonText: "Back" }));
    };
    let closedModalHeading = useIntl().formatMessage({
        id: "app.studentApplication.application.closed.heading",
    }, {
        title: applicationObj.title,
    });
    let closedModalContent = useIntl().formatMessage({
        id: "app.studentApplication.application.closed.content",
    });
    let closedModalAriaLabel = useIntl().formatMessage({
        id: "app.studentApplication.application.closed.aria.label",
    });
    const renderApplicationClosedModal = (isOpen) => {
        let modalHeading = closedModalHeading;
        let modalContent = closedModalContent;
        let ariaLabelValue = closedModalAriaLabel;
        return (_jsx(InformationModal, { isOpen: isOpen, onPrimaryClick: (event) => {
                if (isExternal) {
                    event.preventDefault();
                    setOpenApplicationClosedModal(false);
                    setClosedApp(true);
                }
                else {
                    cancelApplicationHandler(event, true);
                }
            }, modalContent: modalContent, modalHeading: modalHeading, ariaLabelValue: ariaLabelValue, modalId: "information-modal-closed", contentId: "content-information-modal-closed", primaryButtonText: "Back" }));
    };
    let updatedModalHeading = useIntl().formatMessage({
        id: "app.studentApplication.application.updated.heading",
    }, {
        title: applicationObj.title,
    });
    let updatedModalContent = useIntl().formatMessage({
        id: "app.studentApplication.application.updated.content",
    });
    let updatedModalAriaLabel = useIntl().formatMessage({
        id: "app.studentApplication.application.updated.aria.label",
    });
    const renderApplicationUpdatedModal = (isOpen) => {
        let modalHeading = updatedModalHeading;
        let modalContent = updatedModalContent;
        let ariaLabelValue = updatedModalAriaLabel;
        return (_jsx(InformationModal, { isOpen: isOpen, onPrimaryClick: (event) => {
                event.preventDefault();
                loadFunc();
            }, modalContent: modalContent, modalHeading: modalHeading, ariaLabelValue: ariaLabelValue, modalId: "information-modal-updated", contentId: "content-information-modal-updated", primaryButtonText: "Reload" }));
    };
    const canEdit = isNullOrEmpty(studentResponse === null || studentResponse === void 0 ? void 0 : studentResponse.status)
        ? !applicationObj.connectedToArchivedLP
        : ((studentResponse === null || studentResponse === void 0 ? void 0 : studentResponse.status) === "DRAFT" ||
            ((studentResponse === null || studentResponse === void 0 ? void 0 : studentResponse.status) === "CONDITIONALLY_ACCEPTED" &&
                studentResponse.allowResubmission) ||
            ((studentResponse === null || studentResponse === void 0 ? void 0 : studentResponse.status) === "DENIED" &&
                studentResponse.allowResubmission)) &&
            ((_e = studentResponse === null || studentResponse === void 0 ? void 0 : studentResponse.application) === null || _e === void 0 ? void 0 : _e.status) === "OPEN" &&
            !applicationObj.connectedToArchivedLP;
    const getComponentLabel = (applicationComponent, uuid) => {
        return (_jsxs("label", Object.assign({ className: `app-component-label ${applicationComponent.componentLabel ? "textfieldlabel" : ""} ${errorField.get(applicationComponent.componentType.toLowerCase() +
                uuid +
                applicationComponent.uuid)
                ? "error"
                : ""}` }, { children: [applicationComponent.componentLabel, applicationComponent.isMandatory &&
                    applicationComponent.componentLabel && (_jsx("span", Object.assign({ className: "reqd" }, { children: "*" })))] })));
    };
    const setErrorMap = (applicationComponent, uuid) => {
        const key = applicationComponent.componentType.toLowerCase() +
            uuid +
            applicationComponent.uuid;
        errorField.set(key, false);
        setErrorField(errorField);
    };
    const setErrorForSmallText = (applicationComponent, templateUuid, value) => {
        let error = false;
        if (applicationComponent.smallTextType === "EMAIL" &&
            !isNullOrEmpty(value.trim())) {
            error = !validateEmail(value.trim());
        }
        else if (applicationComponent.smallTextType === "PHONE_NUMBER" &&
            !isNullOrEmpty(value.trim())) {
            error = !validatePhoneNumber(value.trim());
        }
        else if (applicationComponent.isMandatory) {
            error = isNullOrEmpty(value.trim());
        }
        const key = applicationComponent.componentType.toLowerCase() +
            templateUuid +
            applicationComponent.uuid;
        errorField.set(key, error);
        setErrorField(errorField);
    };
    const handleDateSelection = (event, applicationComponent, appTemplateUuid) => __awaiter(this, void 0, void 0, function* () {
        const datepicker = event.target;
        const selectedISODate = format(new Date(event.detail.date), YYYYMMDD);
        datepicker.value = yield datepicker.reformatDate("ISO", "US", selectedISODate);
        datepicker.errorMessage = "";
        setErrorMap(applicationComponent, appTemplateUuid);
        updateResponse(appTemplateUuid, applicationComponent.uuid, selectedISODate, []);
    });
    const getDateValue = (templateUuid, applicationComponentUuid) => {
        const value = findValue(templateUuid, applicationComponentUuid);
        if (value &&
            value.length === DATE_FORMAT_LENGTH &&
            isMatch(value, YYYYMMDD)) {
            return format(new Date(value), MMDDYYYY);
        }
        return value;
    };
    const getErrorMessage = (applicationComponent, appTemplateUuid) => {
        const key = applicationComponent.componentType.toLowerCase() +
            appTemplateUuid +
            applicationComponent.uuid;
        const errorValue = errorField.get(key);
        let errorMessage = "";
        if (errorValue) {
            const value = findValue(appTemplateUuid, applicationComponent.uuid);
            if (!value) {
                errorMessage = responseRequiredErrorMessage;
            }
            else if (value.length !== DATE_FORMAT_LENGTH ||
                !isMatch(value, YYYYMMDD)) {
                errorMessage = wrongDateErrorMessage;
            }
        }
        return errorMessage;
    };
    const renderMainContent = () => {
        var _a, _b;
        let defaultComponent = 0;
        let isDefault = false;
        return (_jsxs(_Fragment, { children: [((_a = location === null || location === void 0 ? void 0 : location.state) === null || _a === void 0 ? void 0 : _a.sourcePage) === undefined && (_jsx("h2", Object.assign({ className: "app-title" }, { children: appObj === null || appObj === void 0 ? void 0 : appObj.title }))), (_b = appObj === null || appObj === void 0 ? void 0 : appObj.applicationAppTemplates) === null || _b === void 0 ? void 0 : _b.map((appTemplate) => {
                    var _a;
                    if (isDefault) {
                        defaultComponent++;
                    }
                    return (_a = appTemplate.applicationTemplate.templateComponentList) === null || _a === void 0 ? void 0 : _a.map((applicationComponent) => {
                        var _a, _b, _c, _d, _e, _f, _g, _h;
                        if (applicationComponent.componentType === "DEFAULT_COMPONENT") {
                            isDefault = true;
                        }
                        return (_jsx("div", Object.assign({ id: `application-component-${applicationComponent === null || applicationComponent === void 0 ? void 0 : applicationComponent.uuid}`, className: `application-component-item app-comp-${getIdByKey(applicationComponent.componentType, TemplateComponentList)}` }, { children: applicationComponent.componentType === "SMALL_TEXT_FIELD" ? (_jsx(Inputfield, { titleError: (_a = errorField.get(applicationComponent.componentType.toLowerCase() +
                                    appTemplate.applicationTemplate.uuid +
                                    applicationComponent.uuid)) !== null && _a !== void 0 ? _a : false, dataLabel: applicationComponent.inputText, modalTitle: findValue(appTemplate.applicationTemplate.uuid, applicationComponent === null || applicationComponent === void 0 ? void 0 : applicationComponent.uuid), updateTitleHandler: (event) => {
                                    event.preventDefault();
                                    setErrorForSmallText(applicationComponent, appTemplate.applicationTemplate.uuid, event.target.value);
                                    updateResponse(appTemplate.applicationTemplate.uuid, applicationComponent.uuid, event.target.value, []);
                                }, inputRef: inputRef, modalFor: applicationComponent.inputText, modalId: applicationComponent.uuid, required: applicationComponent.isMandatory, maxLength: applicationComponent.smallTextType == "PHONE_NUMBER"
                                    ? 15
                                    : 50, placeholder: applicationComponent.smallTextType == "PHONE_NUMBER"
                                    ? "123-456-7890"
                                    : "", customError: true, customErrorMessage: (applicationComponent === null || applicationComponent === void 0 ? void 0 : applicationComponent.isMandatory) &&
                                    isNullOrEmpty(findValue(appTemplate.applicationTemplate.uuid, applicationComponent === null || applicationComponent === void 0 ? void 0 : applicationComponent.uuid).trim())
                                    ? responseRequiredErrorMessage
                                    : applicationComponent.smallTextType === "EMAIL"
                                        ? wrongEmailErrorMessage
                                        : applicationComponent.smallTextType ===
                                            "PHONE_NUMBER"
                                            ? wrongPhoneNumberErrorMessage
                                            : "", disabled: !canEdit })) : applicationComponent.componentType === "LINK_RESOURCE" ? (_jsx(ResourceLink, { id: applicationComponent.uuid, url: applicationComponent.inputText, displayText: applicationComponent.componentLabel, appTemplateView: false })) : applicationComponent.componentType ===
                                "LARGE_TEXT_AREA" ? (_jsxs("div", Object.assign({ className: "text-area-field" }, { children: [_jsxs("label", Object.assign({ className: `textfieldlabel ${errorField.get(applicationComponent.componentType.toLowerCase() +
                                            appTemplate.applicationTemplate.uuid +
                                            applicationComponent.uuid)
                                            ? "error"
                                            : ""}`, htmlFor: "largeTxt-" + applicationComponent.uuid }, { children: [applicationComponent.inputText, applicationComponent.isMandatory && (_jsx("span", Object.assign({ className: "reqd" }, { children: "*" })))] })), _jsx("div", Object.assign({ className: "textfield" }, { children: _jsxs("div", Object.assign({ className: "charcount -textarea", id: "-textarea-" + applicationComponent.uuid, onFocus: () => updateClassList("-textarea-" + applicationComponent.uuid, "largeTxt-" + applicationComponent.uuid, true), onBlur: () => updateClassList("-textarea-" + applicationComponent.uuid, "largeTxt-" + applicationComponent.uuid, false) }, { children: [_jsx("textarea", { className: "textfieldinput autosize-textarea", id: "largeTxt-" + applicationComponent.uuid, value: findValue(appTemplate.applicationTemplate.uuid, applicationComponent.uuid), "aria-describedby": "field-largeTxt", "aria-labelledby": "text-area-error", onChange: (event) => {
                                                        event.preventDefault();
                                                        setErrorMap(applicationComponent, appTemplate.applicationTemplate.uuid);
                                                        updateLargeTextAreaHandler(appTemplate.applicationTemplate.uuid, applicationComponent.uuid, event.target.value, applicationComponent.contentLimit);
                                                    }, disabled: !canEdit }), _jsx("div", Object.assign({ className: "count", "aria-live": "polite", "aria-atomic": true, "aria-label": getWordCount(findValue(appTemplate.applicationTemplate.uuid, applicationComponent.uuid)) < applicationComponent.contentLimit
                                                        ? "Words Entered"
                                                        : "Word Limit has been reached" }, { children: String(getWordCount(findValue(appTemplate.applicationTemplate.uuid, applicationComponent.uuid))) +
                                                        "/" +
                                                        applicationComponent.contentLimit })), _jsx("span", Object.assign({ id: "field-largeTxt", className: "max-count-label" }, { children: "has word limit of " +
                                                        applicationComponent.contentLimit }))] })) })), showErrorMessage(applicationComponent, appTemplate.applicationTemplate.uuid)] }))) : applicationComponent.componentType === "ATTACHMENT" ? (_jsx(ResourceAttachment, { id: applicationComponent.uuid, attachedFilesList: getUploaderFiles(applicationComponent), displayLabel: applicationComponent.componentLabel, appTemplateView: false, files: applicationComponent.files, isExternal: isExternal })) : applicationComponent.componentType === "LINK_INPUT" ? (_jsx(StudentInputLink, { id: applicationComponent.uuid, componentId: getIdByKey(applicationComponent.componentType, TemplateComponentList), displayLabel: applicationComponent.inputText, appTemplateView: !canEdit, componentObj: applicationComponent, findUrlValue: findUrlValue, isMandatory: applicationComponent.isMandatory, errorMap: errorField, setErrorMap: setErrorMap, applicationTemplateUuid: appTemplate.applicationTemplate.uuid, isExternal: isExternal })) : applicationComponent.componentType === "FILE_UPLOAD" ? (_jsxs("div", Object.assign({ className: "uploader-container" }, { children: [_jsx(FileUploadComponent, { id: "file-upload-" + applicationComponent.uuid, componentObj: applicationComponent, isStudentView: true, isEditable: canEdit, response: findResponse(appTemplate.applicationTemplate.uuid, applicationComponent.uuid), setFileProcessingInProgress: setIsFileProcessing, errorMap: errorField, setErrorMap: setErrorMap, applicationTemplateUuid: appTemplate.applicationTemplate.uuid, isResubmission: isNullOrEmpty(studentApplicationUuid) ? false : true, isExternal: isExternal }), isExternal &&
                                        studentApplicationUuid &&
                                        ((_b = findResponse(appTemplate.applicationTemplate.uuid, applicationComponent.uuid)) === null || _b === void 0 ? void 0 : _b.files.length) ? (_jsx("span", Object.assign({ className: "uploader-help-text" }, { children: uploaderHelpText }))) : null] }))) : applicationComponent.componentType === "DROPDOWN" ? (_jsx(_Fragment, { children: _jsxs(WmSelect, Object.assign({ label: applicationComponent.componentLabel, labelPosition: "top", id: "select-dropdown-" + applicationComponent.uuid, disabled: !canEdit, requiredField: applicationComponent.isMandatory, errorMessage: errorField.get(applicationComponent.componentType.toLowerCase() +
                                        appTemplate.applicationTemplate.uuid +
                                        applicationComponent.uuid)
                                        ? isNullOrEmpty(findValue(appTemplate.applicationTemplate.uuid, applicationComponent.uuid))
                                            ? dropdownRequiredErrorMessage
                                            : ""
                                        : "" }, { children: [_jsx(WmOption, Object.assign({ id: "option-" +
                                                applicationComponent.uuid +
                                                "-SelectOption", selected: findValue(appTemplate.applicationTemplate.uuid, applicationComponent.uuid).trim() == "", onWmOptionSelected: (event) => {
                                                event.preventDefault();
                                                setErrorMap(applicationComponent, appTemplate.applicationTemplate.uuid);
                                                updateResponse(appTemplate.applicationTemplate.uuid, applicationComponent.uuid, "", []);
                                            } }, { children: translate("app.programApplications.select.option") }), "empty"), (_c = applicationComponent.componentOptionList) === null || _c === void 0 ? void 0 : _c.map((option) => (_jsx(WmOption, Object.assign({ id: `option-${applicationComponent.uuid}-${option.sortOrder}`, selected: findValue(appTemplate.applicationTemplate.uuid, applicationComponent.uuid).trim() == option.text, onWmOptionSelected: (event) => {
                                                event.preventDefault();
                                                setErrorMap(applicationComponent, appTemplate.applicationTemplate.uuid);
                                                updateResponse(appTemplate.applicationTemplate.uuid, applicationComponent.uuid, option.text, []);
                                            } }, { children: option.text }), "option" + option.sortOrder)))] }), "select-" + applicationComponent.componentOptionList) })) : applicationComponent.componentType ===
                                "MULTISELECT_CHECKBOXES" ? (_jsxs("div", Object.assign({ className: "multiselect-checkbox-component" }, { children: [getComponentLabel(applicationComponent, appTemplate.applicationTemplate.uuid), _jsx("p", Object.assign({ className: "checkbox-info-msg" }, { children: translate("app.programApplications.component.multiselect.checkboxes.select.text") })), applicationComponent.componentOptionList.length > 0 &&
                                        ((_d = applicationComponent.componentOptionList) === null || _d === void 0 ? void 0 : _d.map((option) => (_jsxs("div", Object.assign({ className: "checkbox-option" }, { children: [_jsx("input", { type: "checkbox", className: "chkbx-input", id: `checkbox-option-${applicationComponent.uuid}-${option.sortOrder}`, disabled: !canEdit, checked: isOptionsSelected(appTemplate.applicationTemplate.uuid, applicationComponent.uuid, option.uuid), onChange: (event) => {
                                                        setErrorMap(applicationComponent, appTemplate.applicationTemplate.uuid);
                                                        let selectedValues = [];
                                                        let resp = findResponse(appTemplate.applicationTemplate.uuid, applicationComponent.uuid);
                                                        if (resp) {
                                                            selectedValues = [
                                                                ...resp.multiselectOptions,
                                                            ];
                                                        }
                                                        if (event.target.checked) {
                                                            selectedValues.push(option);
                                                        }
                                                        else {
                                                            selectedValues = selectedValues.filter((opt) => opt.uuid !== option.uuid);
                                                        }
                                                        updateResponse(appTemplate.applicationTemplate.uuid, applicationComponent.uuid, "", selectedValues);
                                                    } }), _jsx("label", Object.assign({ id: "option-" + option.uuid, className: "chkbx", htmlFor: `checkbox-option-${applicationComponent.uuid}-${option.sortOrder}` }, { children: option.text }))] }), `checkbox-${applicationComponent.uuid}-${option.sortOrder}`)))), showErrorMessage(applicationComponent, appTemplate.applicationTemplate.uuid)] }))) : applicationComponent.componentType ===
                                "ACKNOWLEDGEMENT_CHECKBOX" ? (_jsxs("div", Object.assign({ className: "acknowledgement-checkbox-component" }, { children: [getComponentLabel(applicationComponent, appTemplate.applicationTemplate.uuid), _jsxs("div", Object.assign({ className: "checkbox-option" }, { children: [_jsx("input", { type: "checkbox", className: "chkbx-input", id: `ack-checkbox-${applicationComponent.uuid}`, disabled: !canEdit, checked: findAckCheckboxValue(appTemplate.applicationTemplate.uuid, applicationComponent.uuid), onChange: (event) => {
                                                    setErrorMap(applicationComponent, appTemplate.applicationTemplate.uuid);
                                                    updateAcknowledgementHandler(appTemplate.applicationTemplate.uuid, applicationComponent.uuid, event);
                                                } }), _jsx("label", Object.assign({ id: applicationComponent.componentType.toLowerCase() +
                                                    appTemplate.applicationTemplate.uuid +
                                                    applicationComponent.uuid, className: "chkbx", htmlFor: `ack-checkbox-${applicationComponent.uuid}` }, { children: _jsxs("div", Object.assign({ className: `ack-label ${errorField.get(applicationComponent.componentType.toLowerCase() +
                                                        appTemplate.applicationTemplate.uuid +
                                                        applicationComponent.uuid) &&
                                                        isNullOrEmpty(applicationComponent.componentLabel)
                                                        ? "textfieldlabel error"
                                                        : ""}` }, { children: [applicationComponent.inputText, applicationComponent.isMandatory &&
                                                            isNullOrEmpty(applicationComponent.componentLabel) && _jsx("span", Object.assign({ className: "reqd" }, { children: "*" }))] })) }))] }), `chkbox-${applicationComponent.uuid}`), showErrorMessage(applicationComponent, appTemplate.applicationTemplate.uuid)] }))) : applicationComponent.componentType === "DATE_SELECTOR" ? (_jsx(WmDatepicker, { id: applicationComponent.componentType.toLowerCase() +
                                    appTemplate.applicationTemplate.uuid +
                                    applicationComponent.uuid, label: applicationComponent.inputText, labelPosition: "top", requiredField: applicationComponent.isMandatory, dateFormat: "mm/dd/yyyy", errorMessage: getErrorMessage(applicationComponent, appTemplate.applicationTemplate.uuid), onWmDatepickerDateSelected: (event) => handleDateSelection(event, applicationComponent, appTemplate.applicationTemplate.uuid), value: getDateValue(appTemplate.applicationTemplate.uuid, applicationComponent.uuid), onInput: (event) => {
                                    setErrorMap(applicationComponent, appTemplate.applicationTemplate.uuid);
                                    updateResponse(appTemplate.applicationTemplate.uuid, applicationComponent.uuid, event.target.value.length === DATE_FORMAT_LENGTH &&
                                        isMatch(event.target.value, MMDDYYYY)
                                        ? format(new Date(event.target.value), YYYYMMDD)
                                        : event.target.value, []);
                                }, disabled: !canEdit })) : applicationComponent.componentType === "RADIO_BUTTON" ? (_jsxs("div", Object.assign({ className: "radio-button-component" }, { children: [getComponentLabel(applicationComponent, appTemplate.applicationTemplate.uuid), applicationComponent.componentOptionList.length > 0 &&
                                        ((_e = applicationComponent.componentOptionList) === null || _e === void 0 ? void 0 : _e.map((option) => (_jsxs("div", Object.assign({ className: "radio-cell" }, { children: [_jsx("input", { type: "radio", name: "radio_button" + applicationComponent.uuid, id: "option" + option.uuid, value: option.uuid, className: "radio-input", checked: isOptionsSelected(appTemplate.applicationTemplate.uuid, applicationComponent.uuid, option.uuid), onChange: () => {
                                                        errorField.set(applicationComponent.componentType.toLowerCase() +
                                                            appTemplate.applicationTemplate.uuid +
                                                            applicationComponent.uuid, false);
                                                        setErrorField(errorField);
                                                        let selectedValues = [];
                                                        selectedValues = [option];
                                                        updateResponse(appTemplate.applicationTemplate.uuid, applicationComponent.uuid, "", selectedValues);
                                                    }, disabled: !canEdit }), _jsx("label", Object.assign({ id: "option-" + option.uuid, htmlFor: "option" + option.uuid, className: "radio" }, { children: option.text }))] }), `radio-${applicationComponent.uuid}-${option.sortOrder}`)))), showErrorMessage(applicationComponent, appTemplate.applicationTemplate.uuid)] }))) : applicationComponent.componentType ===
                                "SECTION_HEADER" ? (_jsx("h3", { children: applicationComponent.inputText })) : applicationComponent.componentType ===
                                "DEFAULT_COMPONENT" ? (defaultComponent > 0 ? ("") : (_jsx(CompositeComponent, { user: isNullOrEmpty((_f = location === null || location === void 0 ? void 0 : location.state) === null || _f === void 0 ? void 0 : _f.student)
                                    ? user
                                    : (_g = location === null || location === void 0 ? void 0 : location.state) === null || _g === void 0 ? void 0 : _g.student, admissionTerm: admissionTerm, cohortTermPresent: !isNullOrEmpty((_h = currentCohort === null || currentCohort === void 0 ? void 0 : currentCohort.entryTermObject) === null || _h === void 0 ? void 0 : _h.uuid), canEdit: canEdit, dropList: isExternal ? oTermList : cTermList, appTemplateView: false, updateAdmissionTerm: updateAdmissionTerm, firstName: firstName, lastName: lastName, email: email, updateFirstName: updateFirstName, updateLastName: updateLastName, updateEmail: updateEmail, errorField: errorField, setErrorField: setErrorField, componentObj: applicationComponent, isExternal: isExternal ? isExternal : false }))) : (_jsx(_Fragment, { children: applicationComponent.inputText })) }), applicationComponent === null || applicationComponent === void 0 ? void 0 : applicationComponent.uuid));
                    });
                }), isExternal && (_jsxs("div", Object.assign({ className: "captcha-div", tabIndex: 0 }, { children: [_jsx(ReCAPTCHA, { sitekey: CAPTCHA_SITE_KEY, ref: captchaRef, onChange: (token) => {
                                if (!isNullOrEmpty(token)) {
                                    setEmptyCaptcha(false);
                                }
                            } }), invalidCaptcha || emptyCaptcha ? (_jsx("div", Object.assign({ className: "captcha-error" }, { children: translate("app.programApplications.distribute.application.external.invalid.captcha") }))) : null] })))] }));
    };
    return (_jsxs("main", Object.assign({ className: "student-application-page" }, { children: [isFetchingStudentResponseData || isFetchingData ? (_jsx(Loading, {})) : isExternal ? (showMessage ? (_jsx(ShowSubmissionMessage, { text: "app.programApplications.distribute.application.external.successful.submission", subText: "app.programApplications.distribute.application.external.successful.submission.subtext" })) : wrongUrl ? (_jsx(ShowSubmissionMessage, { text: "app.programApplications.distribute.application.external.wrong.url.text", subText: "app.programApplications.distribute.application.external.wrong.url.subText" })) : closedApp ? (_jsx(ShowSubmissionMessage, { text: "app.programApplications.distribute.application.external.closed.application.text", subText: "app.programApplications.distribute.application.external.closed.application.subText", infoText: "app.programApplications.distribute.application.external.closed.application.infoText", appName: applicationObj.title })) : (_jsxs(_Fragment, { children: [renderHeader(), _jsx("div", Object.assign({ className: "immersive-container" }, { children: _jsx("section", Object.assign({ className: "student-application-content paper-card" }, { children: renderMainContent() })) }))] }))) : (_jsxs(_Fragment, { children: [renderHeader(), (applicationObj === null || applicationObj === void 0 ? void 0 : applicationObj.connectedToArchivedLP) ? (_jsx("div", Object.assign({ className: "archive-application-content" }, { children: _jsx("div", Object.assign({ className: "archive-warning-text" }, { children: translate("app.application.timeline.student.archive.text") })) }))) : (""), _jsx("div", Object.assign({ className: `immersive-container ${(applicationObj === null || applicationObj === void 0 ? void 0 : applicationObj.connectedToArchivedLP)
                            ? "archived-app-container"
                            : ""}` }, { children: _jsx("section", Object.assign({ className: "student-application-content paper-card" }, { children: renderMainContent() })) }))] })), _jsx(DiscardModal, { isOpen: openDiscardModal, onPrimaryClick: (event) => discardChanges(event), onSecondaryClick: (event) => closeDiscardModalHandler(event), onClose: (event) => closeDiscardModalHandler(event), modalContent: discardModalContentText, modalHeading: discardModalHeadingText, ariaLabelValue: discardModalPrimaryText, modalId: "discard-modal-" + (appObj === null || appObj === void 0 ? void 0 : appObj.uuid), contentId: "content-discard-modal-" + (appObj === null || appObj === void 0 ? void 0 : appObj.uuid), primaryText: discardModalPrimaryText, secondayText: discardModalSecondaryText }), renderDeleteApplicationModal(openApplicationDeletedModal), renderApplicationClosedModal(openApplicationClosedModal), renderApplicationUpdatedModal(openApplicationUpdatedModal)] })));
}
