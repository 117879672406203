import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import translate from "../../../i18n/translate";
export default function Inputfield(props) {
    var _a, _b, _c, _d;
    const length = props.maxLength ? props.maxLength : 100;
    const renderCharacterCount = (lengthOfTitle, maxCount) => {
        if (lengthOfTitle < maxCount)
            return { ariaLiveValue: "polite", ariaLabelText: "Character Entered" };
        else if (lengthOfTitle == maxCount)
            return {
                ariaLiveValue: "assertive",
                ariaLabelText: "Character Limit has been reached",
            };
        else
            return { ariaLiveValue: "off", ariaLabelText: "" };
    };
    const { ariaLiveValue, ariaLabelText } = renderCharacterCount((_a = props.modalTitle) === null || _a === void 0 ? void 0 : _a.length, length);
    return (_jsxs("div", Object.assign({ className: "txt-field-wrapper" }, { children: [_jsxs("label", Object.assign({ className: "textfieldlabel " + (props.titleError ? "error" : ""), htmlFor: "input-" + props.modalId }, { children: [props.dataLabel, props.required ? _jsx("span", Object.assign({ className: "reqd" }, { children: "*" })) : ""] })), _jsxs("div", Object.assign({ className: "textfield " + (props.titleError ? "error" : "") }, { children: [_jsxs("div", Object.assign({ className: "charcount" }, { children: [_jsx("input", { className: "textfieldinput " + (props.titleError ? "invalid" : ""), id: "input-" + props.modalId, type: props.fieldType ? props.fieldType : "text", "aria-required": props.required ? props.required : false, value: props.modalTitle, maxLength: length, onChange: props.updateTitleHandler, ref: props.inputRef, "aria-labelledby": "title-error" + props.modalId, "aria-describedby": "field-title" + props.modalId, placeholder: props.placeholder ? props.placeholder : "", disabled: props.disabled, min: props.minValue, max: props.maxValue }), props.fieldType != "number" && (_jsx("div", Object.assign({ className: "count", "aria-live": ((_b = props.modalTitle) === null || _b === void 0 ? void 0 : _b.length) == 100 ? "assertive" : "polite", "aria-atomic": "true", "aria-label": ariaLabelText }, { children: String(((_c = props.modalTitle) === null || _c === void 0 ? void 0 : _c.length) ? (_d = props.modalTitle) === null || _d === void 0 ? void 0 : _d.length : 0) +
                                    "/" +
                                    length }))), _jsx("span", Object.assign({ id: "field-title" + props.modalId, className: "max-count-label" }, { children: props.modalFor === "label"
                                    ? translate("app.input.error.lengthError", {
                                        length: length,
                                    })
                                    : props.modalFor === "word-limit"
                                        ? translate("app.input.error.lengthError1", {
                                            modalFor: props.modalFor,
                                            min: props.minValue,
                                            max: props.maxValue,
                                        })
                                        : translate("app.input.error.lengthError2", {
                                            modalFor: props.modalFor,
                                            length: length,
                                        }) }))] })), !props.titleError && props.fieldDescription ? (_jsx("div", Object.assign({ className: "field-description condition-info" }, { children: props.fieldDescription }))) : (""), _jsx("div", Object.assign({ className: "error-info" }, { children: _jsx("div", Object.assign({ className: "error input-error", id: "title-error" + props.modalId }, { children: props.titleError
                                ? props.customError
                                    ? props.customErrorMessage
                                    : props.fieldDescriptionAsError
                                        ? props.fieldDescription
                                        : props.modalFor === "label"
                                            ? translate("app.input.error.checkbox")
                                            : translate("app.input.error.generic", {
                                                modalFor: props.modalFor,
                                            })
                                : "" })) }))] }))] })));
}
